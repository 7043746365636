import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import {
  FormStyle,
  getLocaleMonthNames,
  TranslationWidth,
} from "@angular/common";
import { RankingPeriod } from "app/__models/ranking-period";

@Injectable()
export class RankingPeriodsService {
  localeMonthNames = getLocaleMonthNames(
    this.locale,
    FormStyle.Standalone,
    TranslationWidth.Wide
  );
  unavailablePeriodLabel: string = "ranking_period_unavailable";

  constructor(@Inject(LOCALE_ID) public locale: string) {}

  public getRankingPeriodLabel(
    period: RankingPeriod
  ): string {
    let periodLabel = <string>period;
    if (period == RankingPeriod.CURRENT_MONTH) {
      const currentDate = new Date();
      periodLabel = this.localeMonthNames[currentDate.getMonth()];
    } else if (period == RankingPeriod.PREVIOUS_MONTH) {
      let lastMonthDate = new Date();
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
      periodLabel = this.localeMonthNames[lastMonthDate.getMonth()];
    }
    return periodLabel;
  }
}
