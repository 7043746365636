import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Filter } from '../__models/filter';
import { Page } from '../__models/page';
import { Trip } from '../__models/trip';
import { TripDetail } from '../__models/trip-detail';
import { Route } from '../__models/route';
import { Event } from '../__models/event';
import { TranspositionService } from './transposition.service';
import { LeaApiService } from './lea-api.service';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class TripsService {
    private pSize: number = 20;

    constructor(
        private _rest: LeaApiService,
        private transpo: TranspositionService
    ) {}

    private checkFilters(f: Filter): void {
        if (f.minDate != null && f.minDate == f.maxDate) ++f.maxDate;
        if (f.minDistance != null && f.minDistance == f.maxDistance)
            ++f.maxDistance;
        if (f.minDuration != null && f.minDuration == f.maxDuration)
            ++f.maxDuration;
        if (f.minSafetyScore != null && f.minSafetyScore == f.maxSafetyScore)
            ++f.maxSafetyScore;
        if (
            f.minDistractionScore != null &&
            f.minDistractionScore == f.maxDistractionScore
        )
            ++f.maxDistractionScore;
        if (f.minEcoScore != null && f.minEcoScore == f.maxEcoScore)
            ++f.maxEcoScore;
    }

    private adjustDates(trip: Trip): void {
        trip.endDate = moment(trip.endDate).toDate();
        if (!trip.startDate) {
            trip.startDate = trip.endDate;
            trip.startDate.setSeconds(
                trip.startDate.getSeconds() - trip.tripDuration
            );
        } else {
            trip.startDate = moment(trip.startDate).toDate();
        }
    }

    private splitTripAddr(content: any): void {
        for (let i = 0; i < content.length; ++i) {
            if (content[i].departureAddress != null) {
                content[i].departureAddress = content[i].departureAddress.split(
                    ','
                );
            }
            if (content[i].arrivalAddress != null) {
                content[i].arrivalAddress = content[i].arrivalAddress.split(
                    ','
                );
            }
        }
    }

    trips(driver: string, pageNum: number, filter?: Filter): Observable<Page> {
        let uri =
            'admin/drivers/' +
            driver +
            '/trips/page?includeShortTrips=true&pageSize=' +
            this.pSize +
            '&pageNumber=' +
            pageNum;
        let separator = '&';
        if (!!filter) {
            for (let f in filter) {
                let ratio = 1;
                if (f == "minDistance" || f == "maxDistance") {
                  ratio *= 1000;
                }
                if (f == "minDuration" || f == "maxDuration") {
                  ratio *= 60;
                }
                if (filter[f] !== null) {
                    uri += separator + f + '=' + (filter[f] * ratio);
                }
            }
        }
        return this._rest.get(uri).pipe(
            map((response: Page) => {
                let r: Page = response;
                this.splitTripAddr(r.content);
                let trips = r.content;
                for (let i = 0; i < trips.length; ++i) {
                    this.adjustDates(trips[i]);
                }
                let defaultedFilters = {
                    minDistance:
                        r.filter.minDistance == null
                            ? 0
                            : Math.floor(r.filter.minDistance),
                    maxDistance:
                        r.filter.maxDistance == null
                            ? 1000
                            : Math.ceil(r.filter.maxDistance),
                    minDuration:
                        r.filter.minDuration == null
                            ? 0
                            : Math.floor(r.filter.minDuration),
                    maxDuration:
                        r.filter.maxDuration == null
                            ? 600
                            : Math.ceil(r.filter.maxDuration),
                    minSafetyScore:
                        r.filter.minSafetyScore == null
                            ? 0
                            : Math.floor(r.filter.minSafetyScore),
                    maxSafetyScore:
                        r.filter.maxSafetyScore == null
                            ? 10
                            : Math.floor(r.filter.maxSafetyScore),
                    minDistractionScore:
                        r.filter.minDistractionScore == null
                            ? 0
                            : Math.floor(r.filter.minDistractionScore),
                    maxDistractionScore:
                        r.filter.maxDistractionScore == null
                            ? 10
                            : Math.floor(r.filter.maxDistractionScore),
                    minEcoScore:
                        r.filter.minEcoScore == null
                            ? 0
                            : Math.floor(r.filter.minEcoScore),
                    maxEcoScore:
                        r.filter.maxEcoScore == null
                            ? 10
                            : Math.floor(r.filter.maxEcoScore),
                };
                this.checkFilters(defaultedFilters);
                r.filter = defaultedFilters;
                return r;
            })
        );
    }

    deleteTrip(tripId: string): Observable<void> {
        return this._rest.delete('admin/trips/' + tripId);
    }

    route(itinId: string): Observable<Route> {
        return this._rest.get('admin/trips/' + itinId + '/route').pipe(
            map((response) => {
                let r: any = response;
                if (r != null) {
                    let route: Route = {
                        latlng: this.transpo.transpose(r.latitude, r.longitude),
                        date: r.date,
                        screenStatus: r.screenStatus,
                        screenLockedIndex: r.screenLockedIndex,
                        screenLockedTime: r.screenLockedTime,
                        callIndex: r.callIndex,
                        callTime: r.callTime,
                        speedingIndex: r.speedingIndex,
                        speedingTime: r.speedingTime,
                    };
                    return route;
                } else {
                    return null;
                }
            })
        );
    }

    getTripDetail(itinId: string): Observable<TripDetail> {
        return this._rest.get('admin/trips/' + itinId + '/detail');
    }

    getTripEvents(itinId: string): Observable<Event[]> {
        return this._rest.get('admin/trips/' + itinId + '/events');
    }
}
