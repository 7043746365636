import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { RestService } from "./rest.service";

@Injectable()
export class CrashApiService {
  constructor(private _rest: RestService) {}

  get(path: string) {
    return this._rest.get(environment.crashApiEndpoint, path);
  }

  getWithoutAuth(path: string) {
    return this._rest.getWithoutAuth(environment.crashApiEndpoint, path);
  }

  postWithoutAuth(path: string, request: any) {
    return this._rest.postWithoutAuth(
      environment.crashApiEndpoint,
      path,
      request
    );
  }

  postByTeamName(path: string, teamName: string, request: any) {
    return this._rest.postByTeamName(
      environment.crashApiEndpoint,
      path,
      teamName,
      request
    );
  }

  getByCustomer(path: string) {
    return this._rest.getByCustomer(environment.crashApiEndpoint, path);
  }

  put(path: string, request: any) {
    return this._rest.put(environment.crashApiEndpoint, path, request);
  }

  post(path: string, request: any) {
    return this._rest.post(environment.crashApiEndpoint, path, request);
  }

  postByCustomer(path: string, request: any) {
    return this._rest.postByCustomer(
      environment.crashApiEndpoint,
      path,
      request
    );
  }

  delete(path: string, request?: any) {
    return this._rest.delete(environment.crashApiEndpoint, path, request);
  }

  deleteAndReturnHtml(path: string) {
    return this._rest.deleteAndReturnHtml(environment.crashApiEndpoint, path);
  }
}
