import { Injectable } from '@angular/core';
import { LatLngTuple } from 'leaflet';

@Injectable()
export class TranspositionService {

  transpose(lat: number[], lng: number[]): LatLngTuple[] {
    let  n = lat.length;
    if (n != lng.length) {
      throw "Latitude and longitude arrays must be the same size.";
    }
    let ans: LatLngTuple[] = [];
    for (let i = 0; i < n; ++i) {
      ans.push([lat[i], lng[i]]); 
    }
    return ans;
  }
}
