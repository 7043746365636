import { Component, OnChanges}from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import {ActivatedRoute, Router} from '@angular/router';

import { GlobalNotifService } from '../../__services/global-notif.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
    animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class HomeComponent implements OnChanges {

  menuState: string = 'out';

  toggleMenu() {
    // 1-line if statement that toggles the value:
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }

  constructor(
    public route: Router,
    private activatedRoute: ActivatedRoute,
    public notif$: GlobalNotifService
    ) {}

  ngOnChanges() {
  }

  onClickMenu() {
    this.menuState = 'out';
  }

}
