import { Injectable } from '@angular/core';
import { LeaApiService } from './lea-api.service';

@Injectable()
export class SearchService {

  constructor(private _rest: LeaApiService) { }

  public getDriverInfo(str){
    return this._rest.get('admin/driverinfos?characters=' + str);
  }
}
