import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import 'rxjs/add/operator/filter';
import {ChallengesService} from '../../__services/challenges.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: "app-challenge-rules",
  templateUrl: "./challenge-rules.component.html",
  styleUrls: ["./challenge-rules.component.css"],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ChallengeRulesComponent implements OnInit {
  public rules: string = '';
  private challengeId$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private route: ActivatedRoute,
    private challengesService: ChallengesService) {
  }

  ngOnInit() {
    this.route.queryParams
      .filter(params => params.challengeId)
      .subscribe(params => {
        const { challengeId } = params;
        this.challengeId$.next(challengeId)
      })

    this.challengeId$.subscribe(challengeId => {
      this.challengesService.getChallenge(challengeId).subscribe(data => this.rules = data.rules)
    })
  }

}
