import { Component, Input, OnInit } from "@angular/core";
import { ChallengesService } from "../../__services/challenges.service";
import { GroupsService } from "../../__services/groups.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { MenuService } from "../../__services/menu.service";
import { LeaModalParams } from "../__directives/lea-modal.directive";
import { UtilsService } from "../../__services/utils.service";
import {SessionService} from '../../__services/session.service';

@Component({
  selector: "app-challenge-manage",
  templateUrl: "./challenge-manage.component.html",
  styleUrls: ["./challenge-manage.component.css"],
})
export class ChallengeManageComponent implements OnInit {
  @Input() challengeId: string;

  isEditable = false;
  theme: number = 0;
  label = "";
  challenge;
  travelMin;
  travelMax;
  distance;
  distanceMin;
  distanceMax;
  availableThemes;
  distanceConditions;
  image;
  images;
  typeDescriptions;
  notification: boolean;

  // for add
  groups;
  groupsChallenge;
  group;

  modalParams: LeaModalParams;

  constructor(
    private groupsService: GroupsService,
    private challengesService: ChallengesService,
    private router: Router,
    private menu: MenuService,
    private translate: TranslateService,
    private utils: UtilsService,
    private session$: SessionService,
  ) {
    this.travelMin = 10;
    this.travelMax = 40;
    this.distance = 50;
    this.distanceMin = 20;
    this.distanceMax = 400;
    this.images = [
      {
        url: "../../../assets/images/svg/icons8-trophy.svg",
        code: "101",
      },
      { url: "../../../assets/images/svg/icons8-medal.svg", code: "102" },
      {
        url: "../../../assets/images/svg/icons8-medal-first-place.svg",
        code: "103",
      },
      {
        url: "../../../assets/images/svg/icons8-leaderboard.svg",
        code: "104",
      },
      {
        url: "../../../assets/images/svg/icons8-steering-wheel.svg",
        code: "105",
      },
      { url: "../../../assets/images/svg/icons8-leaf.svg", code: "301" },
      {
        url: "../../../assets/images/svg/icons8-natural-food.svg",
        code: "302",
      },
      {
        url: "../../../assets/images/svg/icons8-gas-pump.svg",
        code: "303",
      },
      {
        url: "../../../assets/images/svg/icons8-gas-station.svg",
        code: "304",
      },
      {
        url: "../../../assets/images/svg/icons8-shield.svg",
        code: "401",
      },
      { url: "../../../assets/images/svg/icons8-tire.svg", code: "402" },
      { url: "../../../assets/images/svg/icons8-wheel.svg", code: "403" },
      {
        url: "../../../assets/images/svg/icons8-brake-warning.svg",
        code: "404",
      },
      {
        url: "../../../assets/images/svg/icons8-speedometer.svg",
        code: "405",
      },
      { url: "../../../assets/images/svg/icons8-speed.svg", code: "406" },
      {
        url: "../../../assets/images/svg/icons8-maximum-speed.svg",
        code: "407",
      },
      {
        url: "../../../assets/images/svg/icons8-traffic-light.svg",
        code: "408",
      },
    ];
    translate
      .get([
        "app_speeding",
        "challenges_add_type_all",
        "challenges_add_type_single_group",
        "challenges_add_type_inter_group",
        "challenges_add_title",
        "challenge_manage_detail_title",
        "challenge_manage_edit_title",
      ])
      .subscribe((translations: string[]) => {
        this.typeDescriptions = [
          translations["challenges_add_type_all"],
          translations["challenges_add_type_single_group"],
          "",
          translations["challenges_add_type_inter_group"],
        ];
        this.availableThemes = [];
        [201, 221, 101, 401, 205, 209].forEach((code) =>
          this.availableThemes.push({
            code: code,
            label: this.challengesService.getThemeLabelFromCode(code),
          })
        );
      });
    this.distanceConditions = {
      101: "km",
      102: "km_UR",
      103: "km_EU",
      104: "km_VR",
      201: "km",
      202: "km_UR",
      203: "km_EU",
      204: "km_VR",
      205: "km",
      206: "km_UR",
      207: "km_EU",
      208: "km_VR",
      209: "km",
      210: "km_UR",
      211: "km_EU",
      212: "km_VR",
      213: "km",
      214: "km_UR",
      215: "km_EU",
      216: "km_VR",
      221: "km",
      301: "km",
      302: "km",
      303: "km_UR",
      304: "km_EU",
      305: "km_VR",
      306: "km",
      307: "km_UR",
      308: "km_EU",
      309: "km_VR",
      401: "km",
    };
    this.image = {
      url: "../../../assets/images/svg/icons8-trophy.svg",
      code: "101",
    };
  }

  public isHyperAdmin() {
    return this.utils.isHyperAdmin(this.session$.get())
  }

  private adaptDistanceBounds(distance: number): void {
    if (distance !== null && typeof distance !== "undefined") {
      if (distance > this.distanceMax) {
        this.distanceMax = distance;
      }
      if (distance < this.distanceMin) {
        this.distanceMin = distance;
      }
    }
  }

  private adaptNbTripsBounds(nbTrip: number): void{
    if(nbTrip !== null && typeof nbTrip !== 'undefined') {
      if (nbTrip < this.travelMin) {
        this.travelMin = nbTrip;
      }
      if (nbTrip > this.travelMax) {
        this.travelMax = nbTrip;
      }
    }
  }


  ngOnInit() {
    this.groups = [];
    this.group = {};
    this.groupsChallenge = [];

    if (this.challengeId == null) {
      this.groupsService.getGroupList(null, true).subscribe((data) => {
        this.groups = data;
        this.groups = this.groups.filter((group) => group.label !== "All");
      });
      this.challenge = this.challengesService.getNewDraft();
      this.distance = 50;
      this.isEditable = true;

      this.theme = 101;
      this.label = this.getThemeLabel();
      this.modalParams = {
        header: {
          title: "challenges_add_title",
        },
        footer: {
          buttons: [],
        },
      };
      this.modalParams.footer = {
        buttons: [
          {
            label:
              this.challengeId == null
                ? "challenges_add_button_create"
                : "app_modify",
            type: "main",
            disabled: true,
            action: (resolve, reject) => {
              this.update(resolve, reject);
            },
          },
        ],
      };
    } else {
      this.challengesService
        .getChallenge(this.challengeId)
        .subscribe((data) => {
          this.challenge = data;
          this.isEditable = this.challenge.status == "DRAFT";
          if (this.images) {
            let img = this.images.find(
              (image) => image.code == this.challenge.iconCode
            );
            if (img != undefined) {
              this.image = img;
            }
          }
          this.theme = this.challenge.themeCode;
          this.label = this.getThemeLabel();
          this.distance =
            this.challenge.conditions[
              this.distanceConditions[this.challenge.themeCode]
            ];
          this.adaptDistanceBounds(this.distance);
          this.adaptNbTripsBounds(this.challenge.conditions.nbTrip);
          this.modalParams = {
            header: {
              title: this.isEditable
                ? "challenge_manage_edit_title"
                : "challenge_manage_detail_title",
            },
            footer: {
              buttons: [],
            },
          };
          if (this.isEditable) {
            this.modalParams.footer = {
              buttons: [
                {
                  disabled: true,
                  label: "app_modify",
                  type: "standard",
                  action: (resolve, reject) => {
                    this.update(resolve, reject);
                  },
                },
                {
                  label: "app_publish",
                  type: "main",
                  disabled: false,
                  action: (resolve, reject) => {
                    this.publish(resolve, reject);
                  },
                },
              ],
            };
          }
          this.groupsService.getGroupList(null, true).subscribe((data) => {
            this.groups = data;
            this.groups = this.groups.filter((group) => group.label !== "All");
            this.challenge.groups.forEach((g) => {
              this.groupsChallenge.push(
                this.groups.find((x) => {
                  return x.id == g.id;
                })
              );
            });
          });
        });
    }
  }

  amend(resolve, reject, publish: Boolean): void {
    if (!this.challengesService.checkDates(this.challenge)) {
      reject("banner_error_challenge_incorrect_date");
    } else {
      const challengeToUpdate = {
        challengeId: this.challenge.challengeId,
        title: this.challenge.title,
        description: this.challenge.description,
        themeCode: this.challenge.themeCode,
        iconCode: this.image.code,
        startDate: new Date(this.challenge.startDate).getTime(),
        endDate: new Date(this.challenge.endDate).getTime(),
        type: this.challenge.type,
        conditions: {
          nbTrip: this.challenge.conditions.nbTrip,
        },
        groupIds: this.groupsChallenge.map((group) => group.id),
        draft: true,
      };
      challengeToUpdate.conditions[
        this.distanceConditions[this.challenge.themeCode]
      ] = this.distance;
      this.challengesService.postChallengeUpdate(challengeToUpdate).subscribe(
        (x) => {
          if (publish) {
            this.challengesService.publishChallenge(this.challenge).subscribe(
              () => {
                resolve("published", "challenges_list_confirm_modification");
              },
              (r) => {
                reject("banner_error_" + r.error.errorKey);
              }
            );
          } else {
            resolve("modified", "challenges_list_confirm_modification");
          }
        },
        (r) => {
          reject("banner_error_" + r.error.errorKey);
        }
      );
    }
  }

  publish(resolve, reject) {
    this.amend(resolve, reject, true);
  }

  getThemeLabel() {
    return this.challengesService.getThemeLabelFromCode(this.theme);
  }

  loadImage(challengeImg: string) {
    this.image = challengeImg;
  }

  checkForm(): void {
    if (this.isEditable && this.modalParams.footer.buttons.length > 0) {
      let result: boolean =
        !!this.challenge.description &&
        !!this.challenge.endDate &&
        !!this.challenge.startDate &&
        !!this.challenge.themeCode &&
        !!this.challenge.title;

      if (this.challenge.status === "DRAFT") {
        this.modalParams.footer.buttons[1].disabled = !result;
      }

      result =
        result &&
        (this.challenge.type === 0 ||
          (this.challenge.type === 1 && this.groupsChallenge.length === 1) ||
          (this.challenge.type > 1 && this.groupsChallenge.length > 1));
      this.modalParams.footer.buttons[0].disabled = !result;
    }
  }

  readDate(val): Date {
    const isSafari = window["safari"] !== undefined;
    if (!isSafari) {
      if (!val) {
        return null;
      }
      let newDate = new Date(val);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    } else {
      if (val && val.length === 10) {
        const date = new Date(val);

        return date;
      }
    }
  }

  onchallengeTypeChange() {
    this.groupsChallenge = [];
    this.checkForm();
  }

  addToGroup(group) {
    if (this.challenge.type === 1) {
      this.clearChallengeGroups();
    }
    this.groupsChallenge.push(group);
    this.checkForm();
  }

  getGroups() {
    return this.utils.orderGroups(
      this.groups.filter(
        (group) =>
          !this.groupsChallenge.includes(group) &&
          group.managed &&
          group.status === 0
      )
    );
  }

  clearChallengeGroups() {
    if (this.groupsChallenge.length > 0) {
      this.groupsChallenge = [];
      this.checkForm();
    }
  }

  removeChallenge(groupToRemove) {
    this.groupsChallenge = this.groupsChallenge.filter(
      (group) => group !== groupToRemove
    );
    this.checkForm();
  }

  create(resolve, reject) {
    if (this.challengesService.checkDates(this.challenge)) {
      const challengeToCreate = {
        draft: true,
        title: this.challenge.title,
        description: this.challenge.description,
        themeCode: this.challenge.themeCode,
        iconCode: this.image.code,
        startDate: new Date(this.challenge.startDate).getTime(),
        endDate: new Date(this.challenge.endDate).getTime(),
        type: this.challenge.type,
        conditions: {
          nbTrip: this.challenge.conditions.nbTrip,
        },
        groupIds: this.groupsChallenge.map((group) => group.id),
      };
      challengeToCreate.conditions[
        this.distanceConditions[this.challenge.themeCode]
      ] = this.distance;

      this.challengesService.postNewChallenge(challengeToCreate).subscribe(
        (data) => {
          if (data.status) {
            resolve("added", "banner_info_challenge_publication", true);
            if (this.router.url === "/app/challenges") {
              this.menu.refreshView();
            } else {
              this.router.navigate(["app/challenges/"]);
            }
          } else {
            if (data.errorCode === 1) {
              reject("banner_error_challenge_incorrect_date");
            } else {
              reject("app_error_unknown");
            }
          }
        },
        (r) => {
          reject("app_error_unknown");
        }
      );
    } else {
      reject("banner_error_challenge_incorrect_date");
    }
  }

  update(resolve, reject) {
    if (this.challengeId == null) {
      this.create(resolve, reject);
    } else {
      this.modify(resolve, reject);
    }
  }

  modify(resolve, reject) {
    this.amend(resolve, reject, false);
  }

  isExpired(): Boolean {
    if (!!this.challenge || !!this.challenge.startDate) {
      return false;
    }
    return this.challengesService.isExpired(this.challenge);
  }

  shouldDisplayError(value) {
    return (
      this.isEditable && this.challenge.status === "DRAFT" && value.length === 0
    );
  }
}
