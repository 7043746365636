import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-slide-menu',
  templateUrl: './slide-menu.component.html',
  styleUrls: ['./slide-menu.component.css']
})
export class SlideMenuComponent implements OnInit {
  @Output() onClickMenu = new EventEmitter<boolean>();
  constructor(private router: Router) { }

  ngOnInit() {
  }

  public outAnimation() {
    this.onClickMenu.emit(true);
  }

  goToSynthesis() {
    if ((this.router.url.substr(this.router.url.length - 8, 8)) === 'timeline') {
      this.router.navigate([this.router.url.substr(0, this.router.url.length - 8)]);
    } else {
      this.router.navigate([this.router.url]);
    }
  }

  goToTimeline() {

    if ((this.router.url.substr(this.router.url.length - 8, 8)) !== 'timeline') {
     this.router.navigate([this.router.url + '/timeline']);
    }

  }

}
