import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable()
export class RestService {
  constructor(private _http: HttpClient) {}

  get(apiEndPoint: string, path: string) {
    return this.getWithHeaders(apiEndPoint, path, this.getHeaders(false, true));
  }

  getWithoutAuth(apiEndPoint: string, path: string) {
    return this.getWithHeaders(
      apiEndPoint,
      path,
      this.getHeaders(false, false)
    );
  }

  postWithoutAuth(apiEndPoint: string, path: string, request: any) {
    return this.postWithHeaders(
      apiEndPoint,
      path,
      request,
      this.getHeaders(false, false)
    );
  }

  postByTeamName(
    apiEndPoint: string,
    path: string,
    teamName: string,
    request: any
  ) {
    let headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    headers = headers.append("DriveQuant-Team-Name", teamName);
    return this.postWithHeaders(apiEndPoint, path, request, headers);
  }

  getByCustomer(apiEndPoint: string, path: string) {
    return this.getWithHeaders(apiEndPoint, path, this.getHeaders(true, false));
  }

  put(apiEndPoint: string, path: string, request: any) {
    return this.putWithHeaders(
      apiEndPoint,
      path,
      request,
      this.getHeaders(false, true)
    );
  }

  post(apiEndPoint: string, path: string, request: any) {
    return this.postWithHeaders(
      apiEndPoint,
      path,
      request,
      this.getHeaders(false, true)
    );
  }

  postByCustomer(apiEndPoint: string, path: string, request: any) {
    return this.postWithHeaders(
      apiEndPoint,
      path,
      request,
      this.getHeaders(true, false)
    );
  }

  delete(apiEndPoint: string, path: string, request?: any) {
    return this.deleteWithHeaders(
      apiEndPoint,
      path,
      this.getHeaders(false, true),
      true,
      request
    );
  }

  deleteAndReturnHtml(apiEndPoint: string, path: string) {
    return this.deleteWithHeaders(
      apiEndPoint,
      path,
      this.getHeaders(false, true),
      false
    );
  }

  private getHeaders(customer: boolean, authorization: boolean): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    if (customer && localStorage.getItem("customer")) {
      headers = headers.append(
        "Customer-Name",
        localStorage.getItem("customer")
      );
    }
    if (authorization && JSON.parse(localStorage.getItem("session"))) {
      headers = headers.append(
        "Authorization",
        JSON.parse(localStorage.getItem("session")).token
      );
    }
    return headers;
  }

  private getWithHeaders(
    apiEndpoint: string,
    path: string,
    headers: HttpHeaders
  ): Observable<any> {
    return this._http
      .get(apiEndpoint + path, { headers: headers })
      .pipe(map((response: Response) => response));
  }

  private postWithHeaders(
    apiEndpoint: string,
    path: string,
    request: any,
    headers: HttpHeaders
  ): Observable<any> {
    return this._http
      .post(apiEndpoint + path, request, { headers: headers })
      .pipe(map((response: Response) => response));
  }

  private putWithHeaders(
    apiEndpoint: string,
    path: string,
    request: any,
    headers: HttpHeaders
  ): any {
    return this._http
      .put(apiEndpoint + path, request, { headers: headers })
      .pipe(map((response: Response) => response));
  }

  private deleteWithHeaders(
    apiEndpoint: string,
    path: string,
    headers: HttpHeaders,
    jsonResponse: boolean,
    request?: any
  ): any {
    if (jsonResponse) {
      return this._http.delete(apiEndpoint + path, {
        headers: headers,
        ...(request && { body: request }),
      });
    } else {
      return this._http.delete(apiEndpoint + path, {
        headers: headers,
        responseType: "text",
        ...(request && { body: request }),
      });
    }
  }
}
