import { Injectable } from "@angular/core";
import { Notification } from "../__models/notification";

const DEFAULT_NOTIFICATION_TIMEOUT = 5000;

@Injectable({
  providedIn: "root",
})
export class GlobalNotifService {
  public notif: Notification = null;
  private notifTimeout: any;

  constructor() {}

  set(n: Notification, timeout: number = DEFAULT_NOTIFICATION_TIMEOUT): void {
    this.notif = n;
    clearTimeout(this.notifTimeout);
    if (timeout != null) {
      this.notifTimeout = setTimeout(() => {
        this.notif = null;
      }, timeout);
    }
  }
}
