<div class="content">

  <p class="item title"><!-- <i class="fa fa-user-circle-o" aria-hidden="true"></i> -->

   <img class="icon-menu" src="../../../assets/images/Male_User.png"/> {{'menu_admin' | translate}}</p>
  <p class="item" [class.active]="false" [routerLink]="['./supervisors']">{{'menu_admin_team' | translate}}</p>
	<p class="item" [class.active]="false" [routerLink]="['./groups']">{{'menu_admin_groups' |translate}}</p>
  <p class="item" [class.active]="false" [routerLink]="['./drivers']">{{'menu_admin_drivers' | translate}}</p>
	<p class="item" [class.active]="false">Compte</p>

</div>
