<ngb-accordion #acc="ngbAccordion" class="accordion" activeIds="ngb-panel-0,ngb-panel-map,ngb-panel-accidents,ngb-panel-1,ngb-panel-2">
  <ngb-panel id="ngb-panel-0">
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0"><p class="item title"> <i class="icon-menu icons8-doughnut-chart-2"></i>{{'menu_dashboard' | translate}}<span class="accordion-icon"><i class="fa fa-angle-down" aria-hidden="true"></i></span></p></button>
    </ng-template>
    <ng-template ngbPanelContent>
      <div class="group" *ngFor="let group of groups"  [routerLink]="['/app/dashboard', group.id]">
        <p class="item" [class.active]="id === group.id" (click)="goToGroup(group.id)">
          <span class="group-label" [class.padding-border]="id === group.id" *ngIf="group.label === 'All'">{{'menu_dashboard_community' | translate}}</span>
          <span class="group-label" [class.padding-border]="id === group.id" *ngIf="group.label != 'All'">{{group.label}}</span> </p>
      </div>
    </ng-template>
  </ngb-panel>
  <ngb-panel id="ngb-panel-map" *ngIf="(!!mapAccess || !!trackingAccess) && accessAtLeastOneGroup">
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0"><p ngbPanelToggle class="item title"> <i class="icon-menu fa fa-map-o"></i>  {{"menu_carto" | translate}} <span class="accordion-icon"><i class="fa fa-angle-down" aria-hidden="true"></i></span></p></button>
    </ng-template>
    <ng-template ngbPanelContent>
      <p class="item" [class.active]="menuSelected === 'mapUser'" (click)="goToMap()" *ngIf="!!mapAccess">
        <span class="group-label" [class.padding-border]="menuSelected === 'mapUser'">{{"menu_carto_trips" | translate}}</span>
      </p>
      <p class="item" [class.active]="menuSelected === 'mapTracking'" (click)="goToTracking()" *ngIf="!!trackingAccess">
        <span class="group-label" [class.padding-border]="menuSelected === 'mapTracking'">{{"real_time_monitoring_title" | translate}}</span>
      </p>
    </ng-template>
  </ngb-panel>
  <ngb-panel id="ngb-panel-accidents" *ngIf="!!crashDetection">
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0"><p ngbPanelToggle class="item title"><i class="icon-menu-mimic crash"></i>{{'menu_crash' | translate}} <span class="accordion-icon"><i class="fa fa-angle-down" aria-hidden="true"></i></span></p></button>
    </ng-template>
    <ng-template ngbPanelContent>
      <p class="item" [class.active]="menuSelected === 'crashSupport'" (click)="goToCrashSupport()" [routerLink]="['./crash']">
        <span class="group-label" [class.padding-border]="menuSelected === 'crashSupport'">{{'crash_support' | translate}}</span>
      </p>
      <p class="item" [class.active]="menuSelected === 'crashSearch'" (click)="goToCrashSearch()"
        [routerLink]="['./ranking']">
        <span class="group-label" [class.padding-border]="menuSelected === 'crashSearch'">{{'crash_search' | translate}}</span>
      </p>
    </ng-template>
  </ngb-panel>
  <ngb-panel id="ngb-panel-1">
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0"><p ngbPanelToggle class="item title"><i class="icon-menu icons8-user-options"></i>{{'menu_admin' | translate}}<span class="accordion-icon"><i class="fa fa-angle-down" aria-hidden="true"></i></span></p></button>
    </ng-template>
    <ng-template ngbPanelContent>
        <p class="item" [class.active]="menuSelected === 'supervisors'" (click)="resetId('supervisors')" [routerLink]="['./supervisors']">
          <span class="group-label" [class.padding-border]="menuSelected === 'supervisors'">{{'menu_admin_team' | translate}}</span>
        </p>
        <p class="item" [class.active]="menuSelected === 'groups'" (click)="resetId('groups')" [routerLink]="['./groups']">
          <span class="group-label" [class.padding-border]="menuSelected === 'groups'">{{'menu_admin_groups' | translate}}</span>
        </p>
        <p class="item" [class.active]="menuSelected === 'drivers'" (click)="resetId('drivers')" [routerLink]="['./drivers']">
          <span class="group-label" [class.padding-border]="menuSelected === 'drivers'">{{'menu_admin_drivers' | translate}}</span>
        </p>
    </ng-template>
  </ngb-panel>
  <ngb-panel id="ngb-panel-messg">
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0"><p ngbPanelToggle class="item title"  [routerLink]="['./messages']" style=" border-bottom: 0 !important;"><i class="icon-menu icons8-sms"></i>{{'menu_message' | translate}}</p></button>
    </ng-template>
    <ng-template ngbPanelContent></ng-template>
  </ngb-panel>
  <ngb-panel id="ngb-panel-2">
    <ng-template ngbPanelHeader>
      <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0"><p ngbPanelToggle class="item title"><i class="icon-menu icons8-trophy"></i>{{'menu_reward' | translate}} <span class="accordion-icon"><i class="fa fa-angle-down" aria-hidden="true"></i></span></p></button>
    </ng-template>
    <ng-template ngbPanelContent>
      <p class="item" [class.active]="menuSelected === 'challenges'" (click)="resetId('challenges')" [routerLink]="['./challenges']">
        <span class="group-label" [class.padding-border]="menuSelected === 'challenges'">{{'menu_challenge_list' | translate}}</span>
      </p>
      <p class="item" *ngIf="accessAtLeastOneGroup" [class.active]="menuSelected === 'ranking'" (click)="resetId('ranking')"
        [routerLink]="['./ranking']">
        <span class="group-label" [class.padding-border]="menuSelected === 'ranking'">{{'app_ranking' | translate}}</span>
      </p>
    </ng-template>
  </ngb-panel>
</ngb-accordion>

