import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RestService } from './rest.service'

@Injectable()
export class LeaApiService {
  constructor(private _rest: RestService) {}

  get(path: string) {
    return this._rest.get(environment.apiEndpoint, path);
  }

  getWithoutAuth(path: string) {
    return this._rest.getWithoutAuth(environment.apiEndpoint, path);
  }

  postWithoutAuth(path: string, request: any) {
    return this._rest.postWithoutAuth(environment.apiEndpoint, path, request);
  }

  postByTeamName(path: string, teamName: string, request: any) {
    const data = this._rest.postByTeamName(
      environment.apiEndpoint,
      path,
      teamName,
      request
    );

    return data;
  }

  getByCustomer(path: string) {
    return this._rest.getByCustomer(environment.apiEndpoint, path);
  }

  put(path: string, request: any) {
    return this._rest.put(environment.apiEndpoint, path, request);
  }

  post(path: string, request: any) {
    return this._rest.post(environment.apiEndpoint, path, request);
  }

  postByCustomer(path: string, request: any) {
    return this._rest.postByCustomer(environment.apiEndpoint, path, request);
  }

  delete(path: string, request?: any) {
    return this._rest.delete(environment.apiEndpoint, path, request);
  }

  deleteAndReturnHtml(path: string) {
    return this._rest.deleteAndReturnHtml(environment.apiEndpoint, path);
  }
}
