import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.css']
})
export class DashboardMenuComponent implements OnInit {
  groupId: string;
  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
  }

  goToSynthesis() {
    this.activatedRoute.params.subscribe((params: Params) => {
      const groupId = params['groupId'];
      console.log(groupId);
    });

  }

  goToTimeline() {
    this.groupId = this.activatedRoute.pathFromRoot[this.activatedRoute.pathFromRoot.length - 1].snapshot.params['groupId'];
    console.log(this.groupId);

  }

}
