import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class MenuService {

  // Observable string sources
  private menuApp = new Subject<boolean>();
  private menuView = new Subject<boolean>();

  // Observable string streams
  menuApp$ = this.menuApp.asObservable();
  menuView$ = this.menuView.asObservable();

  // Service message commands
  refresh() {
    this.menuApp.next(true);
  }

   // Service message commands
   refreshView() {
    this.menuView.next(true);
  }

}
