import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Notification } from "../__models/notification";

@Injectable()
export class NotificationService {
  private notification = new Subject<Notification>();

  // Observable string streams
  notification$ = this.notification.asObservable();

  // Service message commands
  setNotification(notif: Notification) {
    this.notification.next(notif);
  }
}
