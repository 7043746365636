<div class="content">
   <div ngbDropdown  placement="bottom-right" class="d-inline-block">

	   <button class="btn btn-secondary" id="dropdownBasic1" ngbDropdownToggle>
			  <div class="d-sm-inline-block d-none">
            <span class="names">
              {{session$.getFirstname()}} <span class="last-name">{{session$.getLastname()}} </span>  <i class="fa fa-angle-down" aria-hidden="true"></i>
            </span>
        </div>

       <div class="d-sm-none">
         <i class="icons8-male-user" style="font-size: 35px;"></i>
       </div>
		</button>

    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic1">
      <button class="dropdown-item" (click)="goToProfile()">{{'profile_user_title' | translate}}</button>
      <button class="dropdown-item" (click)="disconnect()">{{'profile_disconnection' | translate}}</button>
    </div>

   </div>
</div>

