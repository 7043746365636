import { Directive, OnInit, OnChanges, Input, TemplateRef, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { LeaModalHeaderComponent, LeaModalHeaderParams } from '../lea-modal-header/lea-modal-header.component';
import { LeaModalFooterComponent, LeaModalFooterParams } from '../lea-modal-footer/lea-modal-footer.component';

export interface LeaModalParams {
  style?: { color: string, 'background-color': string }; // apply this style to header and footer
  header: LeaModalHeaderParams;
  footer?: LeaModalFooterParams;
}

@Directive({
  selector: '[leaModal]'
})
export class LeaModalDirective implements OnInit, OnChanges {

  @Input('leaModal') params: LeaModalParams;

  headerComponent: any;
  footerComponent: any;
  notifTimeout: any;

  constructor(
    private templateRef: TemplateRef<void>,
    private vcr: ViewContainerRef,
    private cfr: ComponentFactoryResolver) { }

  ngOnInit() {
    const headerFactory = this.cfr.resolveComponentFactory(LeaModalHeaderComponent);
    this.headerComponent = this.vcr.createComponent(headerFactory);
    this.vcr.createEmbeddedView(this.templateRef);
    if (!!this.params.footer) {
      const footerFactory = this.cfr.resolveComponentFactory(LeaModalFooterComponent);
      this.footerComponent = this.vcr.createComponent(footerFactory);
      this.footerComponent.instance.notifChange.subscribe((n) => {
        clearTimeout(this.notifTimeout);
        this.headerComponent.instance.notif = n;
        this.notifTimeout = setTimeout(() => {
          this.headerComponent.instance.notif = null;
        }, 5000);
      });
    }
    this.update();
  }

  update(): void {
    if (!!this.headerComponent) {
      this.headerComponent.instance.params = this.params.header;
      this.headerComponent.instance.style = this.params.style;
    }
    if (!!this.footerComponent) {
      this.footerComponent.instance.params = this.params.footer;
      this.footerComponent.instance.style = this.params.style;
    }
  }

  ngOnChanges(): void {
    this.update();
  }
}
