<div class="box">
  <div *ngIf="params" class="modal-header" [ngStyle]="!style ? {color: '#283141'} : style">
    <h5 class="modal-title"><i *ngIf="!!params.picto" class="icon" [ngClass]="params.picto"></i>{{title}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('dismiss')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="notif-box">
    <ngb-alert *ngIf="!!notif" [type]="notif.type" (close)="notif = null"><i style="font-size: 22px; margin-right: 10px;" class='fa fa-check-circle'></i>{{notif.message | translate : notif.args }}</ngb-alert>
  </div>
</div>
