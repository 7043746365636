import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Session } from '../__models/session';
import { group } from '@angular/animations';

@Injectable()
export class UtilsService {
    public getPreviousMonday(): moment.Moment {
        return moment().startOf('isoweek' as moment.unitOfTime.StartOf);
    }

    public colorLuminance(hex, lum) {
        hex = String(hex).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        lum = lum || 0;

        let rgb = '#',
            c,
            i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(
                16
            );
            rgb += ('00' + c).substr(c.length);
        }

        return rgb;
    }

    async delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    isHyperAdmin(session: Session): boolean {
        return session.hyperAdmin;
    }

    orderGroups(groups: any[]): any[] {
        const communityGroup = groups.find((group) => group.label === 'All');

        return communityGroup
            ? [communityGroup].concat(
                  _.orderBy(
                      groups.filter((g) => g.id !== communityGroup.id),
                      [
                          (g) => {
                              return this.uppercaseGroup(g);
                          },
                      ]
                  )
              )
            : _.orderBy(groups, [
                  (g) => {
                      return this.uppercaseGroup(g);
                  },
              ]);
    }

    uppercaseGroup = (group) => {
        return group.label ? group.label.toUpperCase() : '';
    };
}
