import { Injectable } from '@angular/core';
import { LeaApiService } from './lea-api.service';
import { SessionService } from './session.service';
import { AccessPayload } from '../__models/access';

@Injectable()
export class AdminService {
    constructor(
        private _rest: LeaApiService,
        private _sessionService: SessionService
    ) {}

    public customerEnable(enable: boolean) {
        const payload = {
            companyName: localStorage.getItem('customer'),
            enable: enable,
        };

        return this._rest.put('dqadmin/customerEnable', payload);
    }

    public customerServices(payload: AccessPayload) {
        const companyName = localStorage.getItem('customer');

        return this._rest.put(`dqadmin/teams/${companyName}/access`, payload);
    }
}
