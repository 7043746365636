import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class CustomerGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate() {
        if (localStorage.getItem('customer')) {
            return true;
        }

        this.router.navigate(['/customer']);
        return false;
    }
}