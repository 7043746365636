import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-lea-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.css'],
})

export class LeaInputPasswordComponent {
  @Input() value: string;
  @Output() onPasswordChange = new EventEmitter<string>()
  @Input() required = false;
  @Input() placeholder = '';

  public showPassword: boolean = false
}
