import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { LeaApiService } from "./lea-api.service";
import {
  Challenge,
  ChallengeSendMessagePayload,
  ChallengeStatus,
} from "../__models/challenge";
import * as moment from "moment";
import { MetricStringService } from "./metric-string.service";
import { TranslateService } from "@ngx-translate/core";
import {map} from 'rxjs/operators';

@Injectable()
export class ChallengesService {
  themes: any;
  defaultThemeLabel: string;

  constructor(
    private _rest: LeaApiService,
    private metricsFormat: MetricStringService,
    private translate: TranslateService
  ) {
    translate
      .get([
        "challenge_theme_efficiency_all",
        "challenge_theme_efficiency_urban",
        "challenge_theme_efficiency_extra_urban",
        "challenge_theme_efficiency_expressway",
        "challenge_theme_safety_all",
        "challenge_theme_safety_urban",
        "challenge_theme_safety_extra_urban",
        "challenge_theme_safety_expressway",
        "challenge_theme_braking_all",
        "challenge_theme_braking_urban",
        "challenge_theme_braking_extra_urban",
        "challenge_theme_braking_expressway",
        "challenge_theme_acceleration_all",
        "challenge_theme_acceleration_urban",
        "challenge_theme_acceleration_extra_urban",
        "challenge_theme_acceleration_expressway",
        "challenge_theme_adherence_all",
        "challenge_theme_adherence_urban",
        "challenge_theme_adherence_extra_urban",
        "challenge_theme_adherence_expressway",
        "challenge_theme_phone_distraction",
        "challenge_theme_trip_number",
        "challenge_theme_distance_all",
        "challenge_theme_distance_urban",
        "challenge_theme_distance_extra_urban",
        "challenge_theme_distance_expressway",
        "challenge_theme_duration_all",
        "challenge_theme_duration_urban",
        "challenge_theme_duration_extra_urban",
        "challenge_theme_duration_expressway",
        "app_speeding",
        "app_all",
      ])
      .subscribe((translations: string[]) => {
        this.defaultThemeLabel = translations["app_all"];
        this.themes = {
          101: translations["challenge_theme_efficiency_all"],
          102: translations["challenge_theme_efficiency_urban"],
          103: translations["challenge_theme_efficiency_extra_urban"],
          104: translations["challenge_theme_efficiency_expressway"],
          201: translations["challenge_theme_safety_all"],
          202: translations["challenge_theme_safety_urban"],
          203: translations["challenge_theme_safety_extra_urban"],
          204: translations["challenge_theme_safety_expressway"],
          205: translations["challenge_theme_braking_all"],
          206: translations["challenge_theme_braking_urban"],
          207: translations["challenge_theme_braking_extra_urban"],
          208: translations["challenge_theme_braking_expressway"],
          209: translations["challenge_theme_acceleration_all"],
          210: translations["challenge_theme_acceleration_urban"],
          211: translations["challenge_theme_acceleration_extra_urban"],
          212: translations["challenge_theme_acceleration_expressway"],
          213: translations["challenge_theme_adherence_all"],
          214: translations["challenge_theme_adherence_urban"],
          215: translations["challenge_theme_adherence_extra_urban"],
          216: translations["challenge_theme_adherence_expressway"],
          221: translations["challenge_theme_phone_distraction"],
          301: translations["challenge_theme_trip_number"],
          302: translations["challenge_theme_distance_all"],
          303: translations["challenge_theme_distance_urban"],
          304: translations["challenge_theme_distance_extra_urban"],
          305: translations["challenge_theme_distance_expressway"],
          306: translations["challenge_theme_duration_all"],
          307: translations["challenge_theme_duration_urban"],
          308: translations["challenge_theme_duration_extra_urban"],
          309: translations["challenge_theme_duration_expressway"],
          401: translations["app_speeding"],
        };
      });
  }

  public getNewDraft(): Challenge {
    return {
      challengeId: null,
      title: "",
      description: "",
      startDate: null,
      endDate: null,
      conditions: {
        km: 50,
        nbTrip: 8,
      },
      themeCode: 101,
      iconCode: 101,
      type: 0,
      groups: [],
      status: null,
      creationDate: null,
      author: null,
      draft: true,
    };
  }

  public computeExpiry(startDate: Date): Date {
    let daybefore = new Date(startDate);
    daybefore.setDate(daybefore.getDate() - 1);
    return daybefore;
  }

  public isExpired(challenge: Challenge): boolean {
    let now = new Date();
    now.setHours(0, 0, 0, 0);
    return now > this.computeExpiry(challenge.startDate);
  }

  public computeStatus(challenge: Challenge): ChallengeStatus {
    if (challenge.status == "DRAFT") {
      if (this.isExpired(challenge)) {
        return "EXPIRED";
      }
    }
    return challenge.status;
  }

  public getChallenge(challengeId) {
    return this._rest
      .get("challenge/admin/challenge/" + challengeId)
      .pipe(map((c: any) => {
        c.startDate = !!c.startDate
          ? moment(c.startDate).toDate()
          : c.startDate;
        c.endDate = !!c.endDate ? moment(c.endDate).toDate() : c.endDate;
        c.creationDate = !!c.creationDate
          ? moment(c.creationDate).toDate()
          : c.creationDate;
        return c;
      }));
  }

  public getChallengeParticipants(challengeId) {
    return this._rest.get("challenge/participants/" + challengeId);
  }

  public getChallengeParticipantsWithGroups(challengeId) {
    return this._rest.get("challenge/rankings/" + challengeId);
  }

  public getChallengeParticipantsProgress(challengeId) {
    return this._rest.get(
      "admin/challenges/" + challengeId + "/participants/progress"
    );
  }

  public getChallengeList() {
    return this._rest.get("challenge/admin/challenges");
  }

  public checkDates(challenge: Challenge): Boolean {
    return !this.isExpired(challenge) && this.isLongEnough(challenge);
  }

  public isLongEnough(challenge: Challenge): Boolean {
    const minimalChallengeDuration = 14;
    let latestStart = new Date(challenge.endDate);
    latestStart.setHours(0, 0, 0, 0);
    latestStart.setDate(latestStart.getDate() - minimalChallengeDuration);
    return challenge.startDate <= latestStart;
  }

  public checkGroups(challenge) {
    return challenge.type != 1 || challenge.groupIds.length == 1;
  }

  public postNewChallenge(challenge) {
    return this._rest.post("challenge/create", challenge);
  }

  public publishChallenge(challenge) {
    return this._rest.put(
      "admin/challenges/" + challenge.challengeId + "/status",
      { status: "ACTIVE" }
    );
  }

  public postChallengeUpdate(challenge) {
    return this._rest.put(
      "admin/challenges/" + challenge.challengeId,
      challenge
    );
  }

  public archiveChallenge(challenge) {
    return this._rest.put(
      "admin/challenges/" + challenge.challengeId + "/status",
      { status: "ARCHIVED" }
    );
  }

  public deleteChallenge(id) {
    return this._rest.delete("admin/challenges/" + id + "/draft");
  }

  public sendMessageChallenge(id, payload: ChallengeSendMessagePayload) {
    return this._rest.post(`admin/challenges/${id}/sendMessage`, payload);
  }

  public getChallengeResults(challengeId) {
    return this._rest.get("admin/challenges/" + challengeId + "/results");
  }

  public isDistanceTheme(challenge) {
    return challenge?.themeCode >= 302 && challenge?.themeCode <= 305;
  }

  public isDistractionTheme(challenge) {
    return challenge?.themeCode == 221;
  }

  public isDurationTheme(challenge) {
    return challenge?.themeCode >= 306 && challenge?.themeCode <= 309;
  }

  public isTripNumberTheme(challenge) {
    return challenge?.themeCode == 301;
  }

  public isBreakingTheme(challenge) {
    return challenge?.themeCode >= 205 && challenge?.themeCode <= 208;
  }

  public isSafetyTheme(challenge) {
    return challenge?.themeCode >= 201 && challenge?.themeCode <= 204;
  }

  public isAccelerationTheme(challenge) {
    return challenge?.themeCode >= 209 && challenge?.themeCode <= 212;
  }

  public isEventScoredTheme(challenge) {
    return (
      this.isBreakingTheme(challenge) || this.isAccelerationTheme(challenge)
    );
  }

  public computePreChallengeStartDate(challenge): string {
    if (!challenge) {
      return "??/??/????";
    }
    let preChallengeEndDate: Date = new Date(challenge.startDate);
    preChallengeEndDate.setDate(preChallengeEndDate.getDate() - 10);
    let preChallengeStartDate: Date = new Date(
      preChallengeEndDate.getTime() - (challenge.endDate - challenge.startDate)
    );
    return this.metricsFormat.ddMMyyyy(preChallengeStartDate);
  }

  public computePreChallengeEndDate(challenge): string {
    if (!challenge) {
      return "??/??/????";
    }
    let preChallengeEndDate: Date = new Date(challenge.startDate);
    preChallengeEndDate.setDate(preChallengeEndDate.getDate() - 10);
    return this.metricsFormat.ddMMyyyy(preChallengeEndDate);
  }

  public getThemeLabel(challenge): string {
    return this.getThemeLabelFromCode(challenge.themeCode);
  }

  public getThemeLabelFromCode(code: number): string {
    return this.themes[code] ? this.themes[code] : this.defaultThemeLabel;
  }

  public getThemeCodesOfChallenges(challenges: Array<any>): Array<number> {
    return challenges
      .map((c) => c.themeCode)
      .filter((v, i, a) => a.indexOf(v) === i);
  }
}
