<div class="split-pane content menu" xmlns="http://www.w3.org/1999/html">

	<app-organization></app-organization>

  <div class="padding-groups ">
    <app-groups [(activeTab)]="activeTab"></app-groups>
  </div>
  <div class="link-box">
    <div *ngIf="hasProfileCompanyAccess"><a [routerLink]="['/app/profile/company']" (click)="activeTab = 'contacts'" [attr.active]="activeTab == 'contacts'"><i class="account">&nbsp;</i>{{'menu_link_settings' | translate}}</a>
      <img class="gdpr-icon" src="../../../assets/images/svg/trip_info.svg" *ngIf="isGPDR | async"/>
    </div>
    <div><a [href]="'url_faq' | translate" target="_blank"><i class="faq">&nbsp;</i>{{'menu_link_help' | translate}}</a></div>
    <div><a href="mailto:support-lea@drivequant.com"><i class="email">&nbsp;</i>{{'menu_link_new_post' | translate}}</a></div>
  </div>

</div>
