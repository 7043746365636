import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalNotifService } from '../../__services/global-notif.service';
import { LeaModalButton } from '../../__models/lea-button';
import { Notification } from '../../__models/notification';

export interface LeaModalFooterParams {
  buttons?: Array<LeaModalButton>;
}
@Component({
  selector: 'app-lea-modal-footer',
  templateUrl: './lea-modal-footer.component.html',
  styleUrls: ['./lea-modal-footer.component.css']
})
export class LeaModalFooterComponent {

  locked: boolean = false;

  @Input() params: LeaModalFooterParams;
  @Input() style: any;

  @Output() notifChange = new EventEmitter<Notification>();

  constructor(
    public activeModal: NgbActiveModal,
    public notif$: GlobalNotifService) { }

  exec(action): void {
    if (!this.locked) {
      this.locked = true;
      action(
        (feedback: string, msg: string, warn: boolean = false) => {
          this.notif$.set({ message: msg, type: warn ? "warning" : "success" });
          this.locked = false;
          this.activeModal.close(feedback);
        },
        (msg: string, msgArgs?: object) => {
          this.notifChange.emit({
            message: msg,
            type: "danger",
            args: msgArgs
          });
          this.locked = false;
        }
      );
    }    
  }
}
