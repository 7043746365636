<div *ngIf="params" class="modal-footer" [ngStyle]="!style ? {} : style">
  <!-- Left to right : from higher to lower criticity / from discouraged to encouraged -->
  <ng-container *ngFor="let b of params.buttons">
    <button *ngIf="b.type == 'critic'" type="button" class="btn btn-red" [disabled]="!!b.disabled || locked"
      (click)="exec(b.action)">{{ b.label | translate}}</button>
  </ng-container>
  <!-- If there is no action button, cancel button is named close -->
  <button type="button" class="btn btn-standard"
    (click)="activeModal.close('dismiss')">{{ (!params.buttons || params.buttons.length == 0 ? 'app_close' : 'app_cancel') | translate}}</button>
  <ng-container *ngFor="let b of params.buttons">
    <button *ngIf="b.type != 'critic' && b.type != 'main'" type="button" class="btn btn-standard" [disabled]="!!b.disabled || locked"
      (click)="exec(b.action)">{{ b.label | translate}}</button>
  </ng-container>
  <ng-container *ngFor="let b of params.buttons">
    <button *ngIf="b.type == 'main'" type="button" class="btn btn-green" [disabled]="!!b.disabled || locked"
      (click)="exec(b.action)">{{ b.label | translate}}</button>
  </ng-container>
</div>