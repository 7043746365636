import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../../__services/session.service';
import { UtilsService } from '../../__services/utils.service';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.css'],
})
export class AccountComponent implements OnInit {
    organization: string;

    constructor(
        public session$: SessionService,
        private router: Router,
        public utilsService: UtilsService
    ) {
        this.organization = localStorage.getItem('customer');
    }

    ngOnInit() {}

    disconnect() {
        const session = this.session$.get();
        this.session$.clear();

        if (this.utilsService.isHyperAdmin(session)) {
            this.router.navigate(['hyperadmin']);
        } else {
            this.router.navigate(['login']);
        }
    }

    goToProfile() {
        this.router.navigate(['app/profile/user']);
    }
}
