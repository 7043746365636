import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SupervisorsService } from '../../__services/supervisors.service';
import { GroupsService } from '../../__services/groups.service';

@Component({
  selector: "app-invit",
  templateUrl: "./invit.component.html",
  styleUrls: ["./invit.component.css"],
})
export class InvitComponent implements OnInit {
  user;
  company: string;
  password: string;
  error: boolean = false;
  showPassword: boolean = false;
  emphaseMessage: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private supervisorsService: SupervisorsService,
    private groupsService: GroupsService
  ) {}

  ngOnInit() {}

  setPassword() {
    this.emphaseMessage = false;
    this.user = {
      username:
        this.route.pathFromRoot[this.route.pathFromRoot.length - 1].snapshot
          .queryParams.mail,
      password: this.password,
    };

    this.company =
      this.route.pathFromRoot[
        this.route.pathFromRoot.length - 1
      ].snapshot.queryParams.company;

    this.supervisorsService.password(this.user, this.company).subscribe(
      (data) => {
        this.error = false;
        localStorage.setItem("customer", this.company);
        localStorage.setItem("session", JSON.stringify(data));
        this.groupsService.getGroupList().subscribe(
          (groups) => {
            if (groups && groups.length > 0) {
              this.router.navigate(["app/dashboard/" + groups[0].id]);
            } else {
              this.router.navigate(["app/groups"]);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      },
      (error) => {
        if (error.status == 422) {
          this.emphaseMessage = true;
        } else {
          this.error = true;
        }
      }
    );
  }
}
