import { Injectable } from '@angular/core';
import {environment } from '../../environments/environment';
import { LeaApiService } from './lea-api.service';

@Injectable()
export class MessageService {

  constructor(private _rest: LeaApiService) { }


	post(message: any) {
		return this._rest.post('admin/message', message);
	}

	getMessageList() {
		return this._rest.get('admin/messages/' + 0);
	}

}
