import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../__services/session.service';
import { ProfileService } from '../../__services/profile.service';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
    activeTab: string = '';
    hasProfileCompanyAccess: boolean = false;
    isGPDR: Observable<boolean>;

    constructor(
        private sessionService: SessionService,
        private profileService: ProfileService
    ) {
        this.profileService.gdprSubject.subscribe((data) => {
            this.isGPDR = of(data);
        });
        this.profileService.checkGDPR();
    }

    ngOnInit() {
        this.hasProfileCompanyAccess = this.sessionService
            .get(true)
            .access.includes('ROLE_CUSTOMER');
    }
}
