import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT, Location } from '@angular/common';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { SessionService } from './__services/session.service';
import { UtilsService } from './__services/utils.service';
import { Observable, of } from 'rxjs';
import { ProfileService } from './__services/profile.service';
declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    availableLanguages = ['fr', 'en', 'de', 'it', 'es'];
    public isGPDR: Observable<boolean>;

    showMenu() {
        return localStorage.getItem('session');
    }

    constructor(
        @Inject(DOCUMENT) private _document: HTMLDocument,
        translate: TranslateService,
        location: Location,
        private titleService: Title,
        private ngbModalConfig: NgbModalConfig,
        private bnIdle: BnNgIdleService,
        public session$: SessionService,
        public router: Router,
        public utilsService: UtilsService,
        private profileService: ProfileService
    ) {
        ngbModalConfig.backdrop = 'static';
        ngbModalConfig.keyboard = false;
        if (window.location.origin.indexOf('alertgasoil') > 0) {
            this.setTitle('alertgasoil™| Fuel cost killer');
            this._document
                .getElementById('appFavicon')
                .setAttribute('href', './assets/images/agfavicon.ico');
        } else {
            this.setTitle('DriveQuant');
            this._document
                .getElementById('appFavicon')
                .setAttribute('href', './favicon.ico');
        }

        translate.setDefaultLang('en');
        let code = translate.getBrowserLang();
        if (this.availableLanguages.includes(code)) {
            translate.setDefaultLang(code);
            translate.use(code);
        }

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                gtag('config', 'UA-107947415-2', {
                    page_path: event.urlAfterRedirects,
                });
            }
        });

        this.profileService.gdprSubject.subscribe((data) => {
            this.isGPDR = of(data);
        });
        this.profileService.checkGDPR();
    }

    ngOnInit() {
        // logout after 20 minutes of inactivity
        this.bnIdle.startWatching(1200).subscribe((isTimedOut: boolean) => {
            if (isTimedOut) {
                const session = this.session$.get();
                this.session$.clear();

                if (this.utilsService.isHyperAdmin(session)) {
                    this.router.navigate(['hyperadmin']);
                } else {
                    this.router.navigate(['login']);
                }
            }
        });
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    public isAfterLoginWall() {
        return of(this.router.url.substr(0, 4) === '/app');
    }

    killGpdrBanner() {
        const session = this.session$.get();

        this.session$.set({
            ...session,
            gdpr: false,
        });
    }

    shouldDisplayGpdr() {
        const session = this.session$.get(true);

        return of(session && session.gdpr);
    }
}
