
<div class="row row-center">

	<div class="col-2 d-none d-lg-block menu">

	 	<app-menu></app-menu>

	</div>

	<div class="col-12 d-md-none d-block" style="background-color: #0B4D6E">

    <app-slide-menu [@slideInOut]="menuState" (onClickMenu)="onClickMenu()"></app-slide-menu>

    <div class="row row-center" style="height:50px;">
      <!--position: fixed; width: 100%; z-index: 1000;-->
      <div class="col-2 text-left menu-h">
        <button (click)="toggleMenu()" class="hamburger">
          <span>toggle menu</span>
        </button>
      </div>

      <div class="col-7 text-center menu-h">
        <app-title></app-title>
      </div>

      <div class="col-3 text-right account-picture">
        <app-account></app-account>
      </div>

    </div>
	</div>

	<div class="col-md-12 col-sm-12 col-lg-10 col-xl-10 col-xs-12 menu">

    <div class="row row-center d-md-flex d-none" style="height:70px; background-color: #0B4D6E; ">
      <!--position: fixed; width: 100%; z-index: 1000;-->
      <div class="col-9">
        <app-title></app-title>
      </div>

      <div class="col-3 text-right">
        <app-account></app-account>
      </div>

    </div>

    <!--<div style="padding-top: 80px;">-->
      <router-outlet></router-outlet>

    <!--</div>-->

	</div>

</div>

<div class="notif-box">
  <ngb-alert *ngIf="!!notif$.notif" [type]="notif$.notif.type" (close)="notif$.notif = null"><i style="font-size: 22px; margin-right: 10px;" class='fa fa-check-circle'></i> {{ notif$.notif.message | translate : notif$.notif.args }}</ngb-alert>
</div>
