import { Injectable } from "@angular/core";
import { CrashApiService } from "./crash-api.service";
import { TimePeriod } from "../__models/crashes";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";

export interface TimeInterval {
  beginDate: number;
  endDate: number;
}

export interface TransformationParameters {
  gps: TimeInterval;
  mvt: TimeInterval;
  union: TimeInterval;
}

@Injectable()
export class CrashService {
  stateTranslationKeysByState = {};
  checkStateTranslationKeysByState = {};
  checkStateTrKeyMap = {
    CONFIRMED_BY_OPERATOR: "crash_status_confirmed",
    UNCONFIRMED_BY_OPERATOR: "crash_status_unconfirmed",
    UNDEFINED_BY_OPERATOR: "crash_status_undefined",
    EXPIRED: "crash_status_expired",
    CHECK_REQUIRED: "crash_status_to_be_processed",
    CHECK_NOT_REQUIRED: "crash_status_no_verification",
  };
  annotationAssistanceTrKeyMap = {
    REPAIRMAN: "crash_annotation_assistance_repairman",
    RESCUE: "crash_annotation_assistance_rescue",
    NONE: "crash_annotation_assistance_none",
  };
  annontationTypeTrKeyMap = {
    VEHICLE: "crash_annotation_type_vehicle",
    PEDESTRIAN: "crash_annotation_type_pedestrian",
    DAMAGED_ROAD: "crash_annotation_type_damaged_road",
    ANIMAL: "crash_annotation_type_animal",
    "2_WHEELS": "crash_annotation_type_2_wheels",
    STATIC_OBJECT: "crash_annotation_type_static_object",
    LOSS_OF_CONTROL: "crash_annotation_type_loss_of_control",
    UNKNOWN: "crash_annotation_type_unknown",
    OTHER: "crash_annotation_type_other",
  };
  annotationCollisionAreaTrKeyMap = {
    FRONT: "crash_annotation_front",
    REAR: "crash_annotation_rear",
    LEFT_SIDE: "crash_annotation_left_side",
    RIGHT_SIDE: "crash_annotation_right_side",
    UNKNOWN: "crash_annotation_unknown",
  };

  constructor(
    private translate: TranslateService,
    private _rest: CrashApiService
  ) {
    this.translate
      .get([
        "crash_status_confirmed",
        "crash_status_expired",
        "crash_status_undefined",
        "crash_status_no_verification",
        "crash_status_unconfirmed",
        "crash_status_to_be_processed",
        "crash_state_detected",
        "crash_state_invalid",
        "crash_state_undetected",
      ])
      .subscribe((translations: string[]) => {
        this.checkStateTranslationKeysByState["CONFIRMED_BY_OPERATOR"] =
          translations["crash_status_confirmed"];
        this.checkStateTranslationKeysByState["EXPIRED"] =
          translations["crash_status_expired"];
        this.checkStateTranslationKeysByState["UNDEFINED_BY_OPERATOR"] =
          translations["crash_status_undefined"];
        this.checkStateTranslationKeysByState["CHECK_NOT_REQUIRED"] =
          translations["crash_status_no_verification"];
        this.checkStateTranslationKeysByState["UNCONFIRMED_BY_OPERATOR"] =
          translations["crash_status_unconfirmed"];
        this.checkStateTranslationKeysByState["CHECK_REQUIRED"] =
          translations["crash_status_to_be_processed"];
        this.stateTranslationKeysByState["CONFIRMED_BY_SERVICE"] =
          translations["crash_state_detected"];
        this.stateTranslationKeysByState["IMPOSSIBLE_TO_COMPUTE"] =
          translations["crash_state_invalid"];
        this.stateTranslationKeysByState["UNCONFIRMED_BY_SERVICE"] =
          translations["crash_state_undetected"];
      });
  }

  public getCrashes(
    selectedPeriod: TimePeriod,
    state: string = null,
    userId: string = null,
    itinId: string = null
  ) {
    let uri: string = "/admin/crashes";
    uri += "?";
    if (selectedPeriod != null) {
      uri += "startDate=" + selectedPeriod.from.getTime();
      uri += "&";
      uri += "endDate=" + selectedPeriod.to.getTime();
    } else {
      uri += "startDate=0&endDate=0";
    }
    if (state && state != "all") {
      uri += "&state=" + state;
    }
    if (userId) {
      uri += "&userId=" + userId;
    }
    if (itinId) {
      uri += "&itinId=" + itinId;
    }
    return this._rest.get(uri);
  }

  public postAnnotation(crashId: string, annotation: any) {
    let uri: string = "/admin/crashes/" + crashId + "/annotation";
    return this._rest.post(uri, annotation);
  }

  public normalize(crash): any {
    let newTimestamps = [];
    crash.gps.timestamp.forEach((t) => {
      newTimestamps.push(t * 1000);
    });
    crash.gps.timestamp = newTimestamps;
    if (crash.annotation?.comment === "string") {
      crash.annotation.comment = "";
    }
    return crash;
  }

  public getCrash(crashId: string) {
    let uri: string = "/admin/crashes/" + crashId;
    return this._rest.get(uri);
  }

  public getCheckStateTranslationKey(state: string): string {
    return this.checkStateTranslationKeysByState[state];
  }

  public getStateTranslationKey(state: string): string {
    return this.stateTranslationKeysByState[state];
  }

  private getMinArrayLength(lhs: Array<any>, rhs: Array<any>): number {
    return Math.min(lhs.length, rhs.length);
  }

  private getGpsTrackLength(crash): number {
    if (crash.gps.latitude.length != crash.gps.longitude.length) {
      console.log(
        "WARNING: longitude and latitude arrays are not the same size."
      );
    }
    return this.getMinArrayLength(crash.gps.latitude, crash.gps.longitude);
  }

  public getCrashTrack(crash): Array<{ latitude: number; longitude: number }> {
    let ans = [];
    let gpsTrackLength = this.getGpsTrackLength(crash);
    for (let i = 0; i < gpsTrackLength; ++i) {
      let pinpoint = {
        latitude: crash.gps.latitude[i],
        longitude: crash.gps.longitude[i],
      };
      ans.push(pinpoint);
    }
    return ans;
  }

  private getAccelerationNorm(
    xAccelleration: Array<number>,
    yAccelleration: Array<number>,
    zAccelleration: Array<number>
  ): Array<number> {
    if (
      xAccelleration.length != yAccelleration.length ||
      xAccelleration.length != zAccelleration.length
    ) {
      console.log(
        "WARNING: Inconsistent data. Accelleration vectors are not the same size."
      );
    }
    let minLenght = Math.min(
      this.getMinArrayLength(xAccelleration, yAccelleration),
      this.getMinArrayLength(xAccelleration, zAccelleration)
    );
    let ans = [];
    for (let i = 0; i < minLenght; ++i) {
      let x = xAccelleration[i];
      let y = yAccelleration[i];
      let z = zAccelleration[i];
      let norm = Math.sqrt(x * x + y * y + z * z);
      ans.push(norm / 1000);
    }

    return ans;
  }

  public getAcceleration(crash): Array<number> {
    return this.getAccelerationNorm(
      crash.movement.accelerationX,
      crash.movement.accelerationY,
      crash.movement.accelerationZ
    );
  }

  private extractInterval(
    timestamps: Array<number>,
    crashIndex: number,
    crashDate: Date
  ): TimeInterval {
    let min = Math.min(...timestamps);
    let max = Math.max(...timestamps);
    let offset = timestamps[crashIndex] - min;
    let beginDate = crashDate.getTime() - offset;
    let endDate = beginDate + (max - min);
    return { beginDate: beginDate, endDate: endDate };
  }

  private getUnionInterval(lhs: TimeInterval, rhs: TimeInterval) {
    return {
      beginDate: Math.min(lhs.beginDate, rhs.beginDate),
      endDate: Math.max(lhs.endDate, rhs.endDate),
    };
  }

  public getGraphTransformationParameters(crash): TransformationParameters {
    let crashDate = moment(crash.date).toDate();
    let gpsInterval = this.extractInterval(
      crash.gps.timestamp,
      crash.gpsFeatures.index,
      crashDate
    );
    let mvtInterval = this.extractInterval(
      crash.movement.timestamp,
      crash.movementFeatures.index,
      crashDate
    );
    return {
      gps: gpsInterval,
      mvt: mvtInterval,
      union: this.getUnionInterval(gpsInterval, mvtInterval),
    };
  }

  public getAnnotationCollisionAreaLabel(value): string {
    return this.annotationCollisionAreaTrKeyMap[value];
  }

  public getAnnontationTypeLabel(value): string {
    return this.annontationTypeTrKeyMap[value];
  }

  public getAnnotationAssistanceLabel(value): string {
    return this.annotationAssistanceTrKeyMap[value];
  }

  public getAnnotationDriverRepliedLabel(value): string {
    return value === true
      ? "crash_annotation_answer_driver_yes"
      : "crash_annotation_answer_driver_no";
  }

  public getDriverContactedLabel(value): string {
    return value === true
      ? "crash_annotation_contact_driver_yes"
      : "crash_annotation_contact_driver_no";
  }

  public getCheckStateValues(): Array<string> {
    let ans = [
      "CONFIRMED_BY_OPERATOR",
      "UNCONFIRMED_BY_OPERATOR",
      "UNDEFINED_BY_OPERATOR",
    ];
    return ans;
  }

  public getAnnotationAssistanceValues(): Array<string> {
    return Object.keys(this.annotationAssistanceTrKeyMap);
  }

  public getAnnontationTypeValues(): Array<string> {
    return Object.keys(this.annontationTypeTrKeyMap);
  }

  public getAnnotationCollisionAreaValues(): Array<string> {
    return Object.keys(this.annotationCollisionAreaTrKeyMap);
  }

  public getCheckStateLabel(value): string {
    return this.checkStateTrKeyMap[value];
  }
}


