import { Injectable, OnInit } from '@angular/core';
import { Session } from '../__models/session';

@Injectable()
export class SessionService implements OnInit {
    private session: Session;

    ngOnInit(): void {
        this.session = this.loadFromStorage();
    }

    get(mayLoadLocal: boolean = false): Session {
        if (this.session == null && mayLoadLocal == true) {
            this.session = this.loadFromStorage();
        }
        return this.session;
    }

    set(s: Session): boolean {
        if (this.isValid(s)) {
            this.session = s;
            this.save(s);
            return true;
        }
        return false;
    }

    clear(): void {
        this.session = null;
        this.clearStorage();
    }

    getFirstname(): string {
        if (!!this.session) {
            return this.session.firstname;
        }
        return null;
    }

    getLastname(): string {
        if (!!this.session) {
            return this.session.lastname;
        }
        return null;
    }

    get speeding(): boolean {
        if (!!this.session) {
            return this.session.speeding;
        }

        return false;
    }

    private isValid(s: Session): boolean {
        return true;
    }

    private save(s: Session): void {
        let storage = window.localStorage;
        //if (!!storage) {
        storage.setItem('session', JSON.stringify(s));
        //storage.setItem("sid", s.sid);

        //}
    }

    private clearStorage(): void {
        let storage = window.localStorage;
        let ans = null;
        if (!!storage) {
            storage.removeItem('session');
        }
    }

    private loadFromStorage(): Session {
        let storage = window.localStorage;
        let ans = null;
        if (!!storage) {
            //ans = {
            //sid:  storage.getItem("sid"),
            ///...
            //};
            ans = JSON.parse(localStorage.getItem('session'));
            if (!this.isValid(ans)) {
                // Invalid storage data is cleared.
                this.clearStorage();
                ans = null;
            }
        }
        return ans;
    }
}
