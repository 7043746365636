<div class="content">
	<div class="padding">
    <div class="card card-container">
        <p id="profile-name" class="profile-name-card">{{'inscription_choose_pwd' | translate}}</p>
        <p class="message" [ngStyle]="emphaseMessage ? {color: 'red' } : {}">{{'error_password_not_robust' | translate }}</p>
        <form class="form-signin" (ngSubmit)="setPassword()">
      			<div class="input-group">
						  <input type="{{showPassword ? 'text' : 'password'}}" [(ngModel)]="password" name="password" class="form-control flat" required autofocus>
						  <span class="input-group-addon" (click)="showPassword = !showPassword">
						  	<i class="fa fa-eye" aria-hidden="true"></i>
							</span>
						</div>
            <button class="btn btn-lg btn-primary btn-block btn-signin" type="submit">{{'app_validate' | translate}}</button>
        </form>
        <p *ngIf="error" class="error">{{'banner_error_add_pwd_new_admin' | translate}}</p>
    </div>
  </div>
</div>
