import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeDe from '@angular/common/locales/de';
import localeIt from '@angular/common/locales/it';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { AuthGuard } from './__guards/auth.guard';
import { CustomerGuard } from './__guards/customer.guard';
import { AuthService } from './__services/auth.service';
import { CustomerService } from './__services/customer.service';
import { GroupsService } from './__services/groups.service';
import { DriversService } from './__services/drivers.service';
import { SupervisorsService } from './__services/supervisors.service';
import { NgChartsModule } from 'ng2-charts';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AccountComponent } from './shared/account/account.component';
import { HomeComponent } from './shared/home/home.component';
import { MenuComponent } from './shared/menu/menu.component';
import { GroupsComponent } from './shared/groups/groups.component';
import { SlideMenuComponent } from './shared/slide-menu/slide-menu.component';
import { AdministrationComponent } from './shared/administration/administration.component';
import { InvitComponent } from './shared/invit/invit.component';
import { DashboardMenuComponent } from '../modules/dashboard-menu/dashboard-menu.component';
import { ProfileService } from './__services/profile.service';
import { MessageService } from './__services/message.service';
import { DynamiclinkService } from './__services/dynamiclink.service';
import { OrganizationComponent } from './shared/organization/organization.component';
import { TitleComponent } from './shared/title/title.component';
import { TitleAppService } from './__services/title-app.service';
import { NotificationService } from './__services/notification.service';
import { ChallengesService } from "./__services/challenges.service";
import { CrashService } from "./__services/crash.service";
import { NouisliderModule } from 'ng2-nouislider';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { MenuService } from './__services/menu.service';
import { AutofocusDirective } from './autofocus.directive';
import { TranspositionService } from './__services/transposition.service';
import { TripsService } from './__services/trips.service';
import { SearchService } from './__services/search.service';
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { MetricStringService } from './__services/metric-string.service';
import { RankingPeriodsService } from './__services/ranking-periods.service';
import { RestService } from "./__services/rest.service";
import { LeaApiService } from "./__services/lea-api.service";
import { CrashApiService } from "./__services/crash-api.service";
import { SessionService } from './__services/session.service';
import { PipesModule } from './__pipes/pipes.module';
import { UtilsService } from './__services/utils.service';
import { AdminService } from './__services/admin.service';
import {ChallengeRulesComponent} from './challenges/challenge-rules/challenge-rules.component';
import {ComponentsModule} from './__components/components.module';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

function falbackToEnUS() {
  let browserLocale = window.navigator.language;
  if (
    browserLocale.startsWith("fr") ||
    browserLocale.startsWith("en") ||
    browserLocale.startsWith("es") ||
    browserLocale.startsWith("de") ||
    browserLocale.startsWith("it")
  ) return browserLocale;
  return "en-US";
}

@NgModule({
  declarations: [
    AppComponent,
    AccountComponent,
    HomeComponent,
    MenuComponent,
    GroupsComponent,
    SlideMenuComponent,
    AdministrationComponent,
    InvitComponent,
    DashboardMenuComponent,
    OrganizationComponent,
    TitleComponent,
    ChallengeRulesComponent,
    AutofocusDirective,
  ],
  exports: [],
  imports: [
    BrowserModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgChartsModule,
    PipesModule,
    routing,
    NgbModule,
    BrowserAnimationsModule,
    NouisliderModule,
    MyDateRangePickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    MessageService,
    [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
    AuthGuard,
    CustomerGuard,
    AuthService,
    GroupsService,
    CustomerService,
    DriversService,
    DynamiclinkService,
    SupervisorsService,
    ProfileService,
    TitleAppService,
    NotificationService,
    ChallengesService,
    CrashService,
    MenuService,
    RestService,
    LeaApiService,
    CrashApiService,
    SearchService,
    SessionService,
    TranspositionService,
    TripsService,
    MetricStringService,
    RankingPeriodsService,
    UtilsService,
    AdminService,
    { provide: LOCALE_ID, useValue: falbackToEnUS() },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeFr, "fr");
    registerLocaleData(localeEn, "en");
    registerLocaleData(localeEs, "es");
    registerLocaleData(localeDe, "de");
    registerLocaleData(localeIt, "it");
  }
}
