<div class="row row-center">

<!-- 	<div *ngIf="showMenu()" class="col-3" style="padding-right: 0px;">

		<app-menu></app-menu>

	</div>
 -->
<!-- 	<div [class.col-9]="showMenu()" class="text-center" style="margin: 0 auto;">
 -->
 		<div class="col-12">

      <div *ngIf="isAfterLoginWall() | async" >
        <div *ngIf="shouldDisplayGpdr() | async">
          <div *ngIf="isGPDR | async">
            <div class="row row-center gdpr-card">
              <div class="col-md-10 col-sm-10 col-lg-11 col-xl-11 col-xs-10 pr-0 pl-0">
                <div class="content-gdpr">
                  <a [routerLink]="['/app/profile/company']" class="text-gdpr">{{'gdpr_banner_alert' | translate}}</a>
                </div>
              </div>
              <div class="col-md-2 col-sm-2 col-lg-1 col-xs-2 pr-0 pl-0 text-center">
                <span (click)="killGpdrBanner()" class="cross-alert" aria-hidden="true">&times;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
			<router-outlet></router-outlet>

		</div>
<!-- 	</div>
 -->
</div>
