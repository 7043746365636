import { TranslateService } from "@ngx-translate/core";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { formatNumber } from "@angular/common";
import { Duration } from "../__models/metrics";

@Injectable()
export class MetricStringService {
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public translateService: TranslateService
  ) {}

  durationFromSeconds(sec: number): Duration {
    let x = sec;
    const days = Math.floor(x / (3600 * 24));
    x -= days * (3600 * 24);
    const hours = Math.floor(x / 3600);
    x -= hours * 3600;
    const minutes = Math.floor(x / 60);
    x -= minutes * 60;
    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: Math.floor(x),
    };
  }

  durationString(sec: number): string {
    let ans: string = "";
    let duration: Duration = this.durationFromSeconds(sec);
    if (duration.days) {
      ans +=
        duration.days + ` ${this.translateService.instant("app_short_day")} `;
    }
    if (duration.hours) {
      ans += duration.hours + " h ";
    }
    ans += !duration.days
      ? (duration.hours
          ? ("00" + duration.minutes).slice(-2)
          : duration.minutes) + (!duration.hours ? " min" : "")
      : "";
    return ans;
  }

  customDurationString(sec: number): string {
    let ans = "";
    let duration: Duration = this.durationFromSeconds(sec);
    if (duration.days) {
      ans +=
        duration.days + ` ${this.translateService.instant("app_short_day")} `;
    }
    if (duration.hours) {
      ans += `${duration.hours} h `;
    }
    if (duration.days) {
      return ans;
    }
    if (duration.hours >= 1) {
      if (duration.minutes > 0) {
        ans += `${duration.minutes}`;
      }
      return ans;
    }
    if (duration.minutes >= 10) {
      ans += `${duration.minutes} min`;
      return ans;
    }
    if (duration.minutes >= 1 && duration.minutes < 10) {
      ans += `${duration.minutes} min ${duration.seconds}`;
      return ans;
    }
    if (duration.minutes === 0) {
      return `${duration.seconds} s`;
    }
  }

  customHtmlDurationString(sec: number): string {
    let ans = "";
    let duration: Duration = this.durationFromSeconds(sec);
    if (duration.days) {
      ans += `<span class="Value">${
        duration.days
      } </span><span class="Unit">${this.translateService.instant(
        "app_short_day"
      )}</span>`;
    }
    if (duration.hours) {
      ans += `<span class="Value">${duration.hours} </span><span class="Unit"> h</span>`;
    }
    if (duration.days) {
      return ans;
    }
    if (duration.hours >= 1) {
      if (duration.minutes > 0) {
        ans += `<span class="Value">${duration.minutes}</span>`;
      }
      return ans;
    }
    if (duration.minutes >= 10) {
      ans += `<span class="Value">${duration.minutes} </span><span class="Unit">min </span>`;
      return ans;
    }
    if (duration.minutes >= 1 && duration.minutes < 10) {
      ans += `<span class="Value">${duration.minutes} </span><span class="Unit">min </span><span class="Value">${duration.seconds}</span>`;
      return ans;
    }
    if (duration.minutes === 0) {
      return `<span class="Value">${duration.seconds} </span><span class="Unit">s </span>`;
    }
  }

  ddMMyyyy(d: Date): string {
    var mm = d.getMonth() + 1; // getMonth() is zero-based
    var dd = d.getDate();
    return [
      (dd > 9 ? "" : "0") + dd,
      (mm > 9 ? "" : "0") + mm,
      d.getFullYear(),
    ].join("/");
  }

  ddMMyyyyHHmm(d: Date): string {
    var MM = d.getMonth() + 1; // getMonth() is zero-based
    var HH = d.getHours();
    var mm = d.getMinutes();
    var dd = d.getDate();
    return (
      [
        (dd > 9 ? "" : "0") + dd,
        (MM > 9 ? "" : "0") + MM,
        d.getFullYear(),
      ].join("/") +
      " " +
      (HH > 9 ? "" : "0") +
      HH +
      ":" +
      (mm > 9 ? "" : "0") +
      mm
    );
  }

  HHmm(d: Date): string {
    var HH = d.getHours();
    var mm = d.getMinutes();
    return (HH > 9 ? "" : "0") + HH + ":" + (mm > 9 ? "" : "0") + mm;
  }

  hours(sec: number, units: boolean = true): string {
    return "" + Math.floor(sec / 3600) + (units ? " h" : "");
  }

  minutes(sec: number, units: boolean = true): string {
    let x = sec;
    let hours = Math.floor(sec / 3600);
    x = sec - hours * 3600;
    return "" + Math.floor(x / 60) + (units ? " min" : "");
  }

  seconds(sec: number, units: boolean = true): string {
    return "" + (sec % 60) + (units ? " sec" : "");
  }

  distanceString(meters: number, units: boolean = true): string {
    return (
      formatNumber(Math.round(meters / 100) / 10, this.locale) +
      (units ? " km" : "")
    );
  }

  customDistanceString(meters: number): string {
    if (meters < 1000) {
      return `${Math.round(meters)} m`;
    }
    return formatNumber(Math.round(meters / 100) / 10, this.locale) + " km";
  }

  customHtmlDistanceString(meters: number): string {
    if (meters < 1000) {
      return `<span class="Value">${Math.round(
        meters
      )} </span><span class="Unit">m </span>`;
    }
    return `<span class="Value">${formatNumber(
      Math.round(meters / 100) / 10,
      this.locale
    )} </span><span class="Unit">km</span>`;
  }

  speedString(kmh: number, units: boolean = true): string {
    return Math.round(kmh) + (units ? " km/h" : "");
  }

  gAccelerationString(acceleration: number): string {
    return Math.round(acceleration * 100) / 100 + " G";
  }

  percentString(value: number): string {
    return Math.round(value) + " %";
  }
}
