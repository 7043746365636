import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";

import { SynthesisGroup } from '../__models/synthesis-group';
import { environment } from '../../environments/environment';
import { LeaApiService } from './lea-api.service';


@Injectable()
export class GroupsService {
  constructor(private _rest: LeaApiService) {}

  getGroupList(
    substituteCommunityName: string | null = null,
    sort: boolean = false,
    filterInactiveGroups: boolean = false,
    filterNonManagedGroups: boolean = false
  ) {
    return this._rest.get("admin/groups").pipe(
      map((groups: any[]) => {
        if (filterInactiveGroups) {
          groups = this.filterInactiveGroups(groups);
        }
        if (filterNonManagedGroups) {
          groups = this.filterNonManagedGroups(groups);
        }
        if (sort) {
          groups = this.orderGroups(groups);
        }
        if (substituteCommunityName !== null) {
          let community = this.findCommunityGroup(groups);
          if (community) {
            community.label = substituteCommunityName;
          }
        }
        return groups;
      })
    );
  }

  getGroup(group: string) {
    return this._rest.get("admin/group/" + group);
  }

  getSynthesis(group: string): Observable<SynthesisGroup> {
    return this._rest.get("admin/group/synthesis/" + group);
  }

  getTimeline(group: string): Observable<any> {
    return this._rest.get("admin/group/timeline/" + group);
  }

  postNewGroup(group): Observable<any> {
    return this._rest.post("group/create", group);
  }

  postNewSupervisor(
    supervisor: string,
    group: string,
    permission: number
  ): Observable<any> {
    return this._rest.post("group/addoperator", {
      groupId: group,
      operatorId: supervisor,
      status: permission,
    });
  }

  removeSupervisor(supervisor: string, group: string): Observable<any> {
    return this._rest.post("group/removeoperator", {
      groupId: group,
      operatorId: supervisor,
    });
  }

  postGroupUpdate(group): Observable<any> {
    return this._rest.post("group/update", group);
  }

  postNewDriver(driverId, group) {
    return this._rest.post("admin/group/adddrivers", {
      driverIds: [driverId],
      groupId: group,
    });
  }

  disable(group: string): Observable<any> {
    return this._rest.get("group/disablegroup/" + group);
  }

  enable(group: string): Observable<any> {
    return this._rest.get("group/enablegroup/" + group);
  }

  delete(groupId: string): Observable<any> {
    return this._rest.delete("group/" + groupId);
  }

  isCommunityGroup(group): boolean {
    return group.label === "All"; // todo: check this is the right test.
  }

  findCommunityGroup(groups: Array<any>): any | null {
    return groups.find((group) => this.isCommunityGroup(group));
  }

  private filterInactiveGroups(groups: any[]): any[] {
    return groups.filter((group) => group.status === 0);
  }

  private filterNonManagedGroups(groups: any[]): any[] {
    return groups.filter((group) => group.managed);
  }

  private orderGroups(groups: any[]): any[] {
    const communityGroup = this.findCommunityGroup(groups);
    return communityGroup
      ? [communityGroup].concat(
          _.orderBy(
            groups.filter((g) => g.id !== communityGroup.id),
            [
              (g) => {
                return this.uppercaseGroupLabel(g);
              },
            ]
          )
        )
      : _.orderBy(groups, [
          (g) => {
            return this.uppercaseGroupLabel(g);
          },
        ]);
  }

  private uppercaseGroupLabel(group) {
    return group.label ? group.label.toUpperCase() : "";
  }
}
