import { Injectable } from '@angular/core';
import { LeaApiService } from './lea-api.service';
import { interval } from 'rxjs/internal/observable/interval';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { SessionService } from './session.service';

const NB_EXPIRED_DRIVERS = 20;

@Injectable()
export class ProfileService {
  gdprSubject: Subject<boolean> = new Subject<boolean>();

  checkGDPR() {
    [100, 1000, 2000, 5000, 10000, 30000].forEach((x) => {
      setTimeout(() => {
        const session = this.sessionService.get(true);
        if (
          session &&
          session.token &&
          (session.hyperAdmin || session.access?.includes("ROLE_CUSTOMER"))
        ) {
          this.getCustomerAccount().subscribe((data: any) => {
            this.gdprSubject.next(
              data && data.nbExpiredDrivers > NB_EXPIRED_DRIVERS
            );
          });
        }
      }, x);
    });
  }

  constructor(
    private _rest: LeaApiService,
    private sessionService: SessionService
  ) {}

  getOperator() {
    return this._rest.get("admin/operator");
  }

  postOperatorUpdate(profile) {
    return this._rest.put("admin/driver", {
      driverId: profile.username,
      lastname: profile.lastname,
      firstname: profile.firstname,
    });
  }

  putPasswordUpdate(oldPassword, newPassword) {
    return this._rest.put("v3/driver/password", {
      oldPassword: oldPassword,
      password: newPassword,
    });
  }

  forgetPassword(team: string, username: string) {
    localStorage.setItem("customer", team);

    return this._rest.postByCustomer("dqadmin/team/lostpassword", {
      username: username,
    });
  }

  getCustomerAccount() {
    return this._rest.get("admin/customer/account").pipe(map((a) => {
      a.creationDate = new Date(a.creationDate);
      if (!a.contact.companyName) {
        a.contact.companyName = "";
      }
      if (!a.contact.country) {
        a.contact.country = "";
      }
      if (!a.notificationSenderName) {
        a.notificationSenderName = "";
      }
      return a;
    }));
  }

  putCustomerAccount(account) {
    return this._rest.put("admin/customer/account", account);
  }

  resetCompanyCode() {
    return this._rest.get("admin/customer/code/renew");
  }

  deleteExpiredDrivers(ids: string[]) {
    return this._rest.delete("/admin/drivers", { ids }).pipe(map((data) => {
      this.checkGDPR();
      return data;
    }));
  }
}
