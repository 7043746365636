import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { LeaApiService } from './lea-api.service';



@Injectable()
export class SupervisorsService {

  constructor(private _rest: LeaApiService) { }

	getOperatorList() {
		return this._rest.get('customer/operators');
	}

	getOperator(supervisorId: string) {
		return this._rest.get('customer/operator/' + supervisorId);
	}

	postNewSupervisor(supervisors) {
		return this._rest.post('customer/operator', supervisors);
	}

	password(supervisor, company) {
		localStorage.setItem('customer', company);
		return this._rest.postByCustomer('v3/operator/password', supervisor);
	}

	postSupervisorUpdate(supervisor) {
		return this._rest.post('admin/operator/update', supervisor);
	}

	resendInvit(supervisorId: string) {
		return this._rest.get('customer/operator/invite/' + supervisorId);
	}

	removeSupervisorRights(supervisorId){
		return this._rest.delete('admin/drivers/' + supervisorId + '/operator');
	}

  cancelSupervisorInvit(supervisorId) {
    return this._rest.delete(`customer/operator/invitations/${supervisorId}`);
  }

  }
