import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { GroupsService } from '../../__services/groups.service';
import { Group } from '../../__models/group';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChallengeManageComponent } from '../../modals/challenge-manage/challenge-manage.component';
import { MenuService } from '../../__services/menu.service';
import { UtilsService } from '../../__services/utils.service';
import { ProfileService } from "../../__services/profile.service";
import { SessionService } from '../../__services/session.service';

@Component({
  selector: "app-groups",
  templateUrl: "./groups.component.html",
  styleUrls: ["./groups.component.css"],
})
export class GroupsComponent implements OnInit {
  groups: any;
  id: string;
  currentGroupId: string;
  menuSelected: string;
  userDriverId: string;
  mapAccess: boolean;
  trackingAccess: boolean;
  accessAtLeastOneGroup: boolean;
  crashDetection: boolean = false;

  @Input() set activeTab(t: string) {
    this.menuSelected = t;
    if (this.menuSelected) {
      this.id = "";
    }
  }

  @Output() activeTabChange = new EventEmitter<string>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private groupsService: GroupsService,
    private modalService: NgbModal,
    private menu: MenuService,
    private utils: UtilsService,
    private session: SessionService,
    private profileService: ProfileService
  ) {
    menu.menuApp$.subscribe((refresh) => {
      if (refresh) {
        this.loadGroup();
      }
    });
    this.mapAccess = JSON.parse(localStorage.getItem("session")).map;
    this.trackingAccess = JSON.parse(
      localStorage.getItem("session")
    ).realTimeLocation;
    this.crashDetection = JSON.parse(
      localStorage.getItem("session")
    ).crashDetection;
    this.userDriverId = JSON.parse(localStorage.getItem("session")).driverId;
  }

  isHyperAdmin(): boolean {
    return !!this.session.get()?.hyperAdmin;
  }

  ngOnInit() {
    this.loadGroup();
  }

  loadGroup() {
    this.groupsService.getGroupList(null, true, true).subscribe(
      (data) => {
        this.groups = data;
        this.groups = this.removeDuplicates(this.groups, "id");
        if (this.groups.length > 0) {
          this.id = this.groups[0].id;
          this.currentGroupId = this.id;
        }
        this.accessAtLeastOneGroup = this.groups.some((group) => group.managed);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public goToGroup(groupId: string) {
    this.id = groupId;
    this.currentGroupId = this.id;
    this.menuSelected = "";

    this.activeTabChange.emit(this.menuSelected);
  }

  public goToMap(): void {
    this.resetId("mapUser");
    this.router.navigate([
      "app/dashboard/" + this.currentGroupId + "/drivers/" + this.userDriverId,
      { defaultToMap: true },
    ]);
  }

  public goToTracking(): void {
    this.resetId("mapTracking");
    this.router.navigate([
      "app/dashboard/" + this.currentGroupId + "/tracking",
    ]);
  }

  public resetId(menu: string) {
    this.menuSelected = menu;
    this.id = "";
    this.activeTabChange.emit(this.menuSelected);
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  public addChallenge() {
    const modalRef = this.modalService.open(ChallengeManageComponent, {
      size: "lg",
      windowClass: "modal-xxl",
    });
    modalRef.componentInstance.challengeId = null;
    modalRef.result.then((reason) => {
      if (reason === "added") {
        this.resetId("challenges");
      }
    });
  }

  public goToCrashSupport() {
    this.resetId("crashSupport");
    this.router.navigate(["app/crash/crash-support"]);
  }

  public goToCrashSearch() {
    this.resetId("crashSearch");
    this.router.navigate(["app/crash/crash-search"]);
  }
}
