import { AuthGuard } from "./__guards/auth.guard";
import { CustomerGuard } from "./__guards/customer.guard";
import { ChallengeRulesComponent } from "./challenges/challenge-rules/challenge-rules.component";
import { HomeComponent } from "./shared/home/home.component";
import { InvitComponent } from "./shared/invit/invit.component";
import { Routes, RouterModule } from "@angular/router";

const appRoutes: Routes = [
  {
    path: "hyperadmin",
    loadChildren: () =>
      import("./hyperadmin/hyperadmin.module").then((m) => m.HyperadminModule),
  },
  {
    path: "customer",
    loadChildren: () =>
      import("./customer/customer.module").then((m) => m.CustomerModule),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./register/register.module").then((m) => m.RegisterModule),
  },
  { path: "invite", component: InvitComponent },
  { path: "challenge-rules", component: ChallengeRulesComponent },
  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "app",
    component: HomeComponent,
    children: [
      {
        path: "supervisors",
        loadChildren: () =>
          import("./supervisors/supervisors.module").then(
            (m) => m.SupervisorsModule
          ),
        canActivate: [AuthGuard, CustomerGuard],
      },
      {
        path: "groups",
        loadChildren: () =>
          import("./groups/groups.module").then((m) => m.GroupsModule),
        canActivate: [AuthGuard, CustomerGuard],
      },
      {
        path: "ranking",
        loadChildren: () =>
          import("./ranking/ranking.module").then((m) => m.RankingModule),
        canActivate: [AuthGuard, CustomerGuard],
      },
      {
        path: "dashboard/:groupId",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        canActivate: [AuthGuard, CustomerGuard],
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./profile/profile.module").then((m) => m.ProfileModule),
        canActivate: [AuthGuard, CustomerGuard],
      },
      {
        path: "drivers",
        loadChildren: () =>
          import("./drivers/drivers.module").then((m) => m.DriversModule),
        canActivate: [AuthGuard, CustomerGuard],
      },
      {
        path: "challenges",
        loadChildren: () =>
          import("./challenges/challenges.module").then(
            (m) => m.ChallengesModule
          ),
        canActivate: [AuthGuard, CustomerGuard],
      },
      {
        path: "crash",
        loadChildren: () =>
          import("./crash/crash.module").then((m) => m.CrashModule),
        canActivate: [AuthGuard, CustomerGuard],
      },
      {
        path: "messages",
        loadChildren: () =>
          import("./messages/messages.module").then((m) => m.MessagesModule),
        canActivate: [AuthGuard, CustomerGuard],
      },
    ],
  },
  { path: "**", redirectTo: "login", pathMatch: "full" },
];

export const routing = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });
