import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit {

  organization: string;

  constructor() { }

  ngOnInit() {
    this.organization = localStorage.getItem('customer');
  }

}
