import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LeaApiService } from './lea-api.service';

@Injectable()
export class CustomerService {

  constructor(private _rest: LeaApiService) {   }

  public valid(customer){
    return this._rest.getWithoutAuth('customer/valid/' + customer)
  }

  public create(user){
    return this._rest.postByCustomer('customer/create', user);
  }

	getActivity(): Observable<any> {
		return this._rest.get('customer/customerActivity');
	}

}
