import { Injectable } from '@angular/core';

import { Session } from '../__models/session';
import { SessionService } from './session.service';
import { LeaApiService } from './lea-api.service';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class AuthService {
    constructor(
        private _rest: LeaApiService,
        private _sessionService: SessionService
    ) {}

    isConnected(mayUseLocal: boolean = false): boolean {
        return this._sessionService.get(mayUseLocal) != null;
    }

    public validHyperadmin(customer: string, credentials: any) {
        return this._rest
            .postByTeamName(
                'authentication/dqadmin/login',
                customer,
                credentials
            )
            .pipe(map((user: any) => {
                this.getSessionFromAuthentication(user);
            }));
    }

  login(username: string, password: string) {
    this._sessionService.clear();
    let payload = { username: username, password: password };

    return this._rest.postByCustomer("admin/auth", payload).pipe(map((user: any) => {
      this.getSessionFromAuthentication(user);
    }));
  }

  renewPassword(payload: {
    password: string;
    hyperAdmin: boolean;
    username: string;
    code: string;
    teamName: string;
  }) {
    const endPoint = "admin/password/renewal";
    return this._rest
      .postByCustomer(endPoint, payload)
      .pipe(switchMap(() => {
        if (payload.hyperAdmin) {
          return this.validHyperadmin(payload.teamName, {
            username: payload.username,
            password: payload.password,
          });
        }
        return this.login(payload.username, payload.password);
      }));
  }

    getSessionFromAuthentication(user: any) {
        if (user && user.token) {
            const { hyperAdmin } = this.parseJwt(user.token);
            const s: Session = {
                driverId: user.driverId,
                firstname: user.firstname,
                lastname: user.lastname,
                map: user.map,
                realTimeLocation: user.realTimeLocation,
                crashDetection: user.crashDetection,
                notRanked: user.notRanked,
                pseudo: user.pseudo,
                tileToken: user.tileToken,
                token: user.token,
                speeding: user.speeding,
                access: new Array(),
                hyperAdmin: hyperAdmin,
                gdpr: true,
            };
            this._sessionService.set(s);
        }
    }

    getAccess() {
        return this._rest.get('admin/access');
    }

    parseJwt(token) {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    }
}
