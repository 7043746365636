import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

export interface LeaModalHeaderParams {
  title: string; // translation key expected
  picto?: string; // icon8 class string identifier expected
  args?: any; // parameter object for internationalization
}

@Component({
  selector: 'app-lea-modal-header',
  templateUrl: './lea-modal-header.component.html',
  styleUrls: ['./lea-modal-header.component.css']
})
export class LeaModalHeaderComponent implements OnInit {

  @Input() params: LeaModalHeaderParams;
  @Input() notif: Notification;
  @Input() style: any;

  title: string;

  constructor(
    public activeModal: NgbActiveModal,
    private translate: TranslateService) { }

  ngOnInit() : void {
    if (!this.params.args) {
      this.translate.get(this.params.title).subscribe(
        (translation: string) => {
          this.title = translation;
        }
      );
    } else {
      this.translate.get(this.params.title, this.params.args).subscribe(
        (translation: string) => {
          this.title = translation;
        }
      );
    }
  }
}
