import {Component, OnInit} from '@angular/core';
import {TitleAppService} from '../../__services/title-app.service';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})

export class TitleComponent implements OnInit {

  titleApp: string;

  constructor(private title: TitleAppService) {
    title.titleApp$.subscribe( newTitle => {
      this.titleApp = newTitle;
    });
  }

  ngOnInit() {}


}
