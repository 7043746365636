import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { LeaInputPasswordComponent } from "./input-password/input-password.component";

@NgModule({
  imports: [CommonModule, FormsModule],
  exports: [
    LeaInputPasswordComponent
  ],
  declarations: [LeaInputPasswordComponent]
})
export class ComponentsModule {}

