<ng-container *ngIf="!!challenge">
  <div class="content" *leaModal="modalParams">
    <div class="modal-body">
      <div class="row row-center">
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <div class="row row-center">
            <div class="col-xl-2 col-lg-2 col-md-2 col-12 col-xs-12">
              <div ngbDropdown class="d-inline-block">
                <button class="btn btn-secondary btn-image" id="dropdownBasic1" ngbDropdownToggle
                  [disabled]="!isEditable">
                  <img src="{{image.url}}" />
                </button>
                <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic1">
                  <div class="icons-selector"><!--
                 --><div *ngFor="let challengeImage of images">
                      <div>
                        <img (click)="loadImage(challengeImage); checkForm();" src="{{challengeImage.url}}" />
                      </div>
                    </div><!--
               --></div>
                </div>
              </div>
            </div>

            <div class="col-xl-10 col-lg-10 col-md-10 col-12 col-xs-12">
              <div class="form-group">
                <label>{{'challenge_manage_label_name' | translate}}</label>
                <input [ngClass]="{ 'has-danger': shouldDisplayError(challenge.title) }" [appAutofocus]="isEditable" type="text" [(ngModel)]="challenge.title"
                  (ngModelChange)="checkForm();" name="name" class="form-control flat" [disabled]="!isEditable">
                <span class="error-message" *ngIf="shouldDisplayError(challenge.title)">{{ 'challenge_manage_error_title_empty' | translate }}</span>
              </div>
            </div>
          </div>
          <div class="row row-center" *ngIf="!!challenge.status">
            <div class="col-12">
              <div class="form-group">
                <label>{{'app_status' | translate}}</label>
                <input type="text" [ngModel]="'challenge_' +  challenge.status | translate"
                  [placeholder]="'challenge_manage_status_placeholder' | translate" name="status"
                  class="form-control flat" [disabled]="true">
                <div class="comment" *ngIf="challenge.status == 'DRAFT'"><span>{{ 'challenge_manage_publication_comment' | translate }}</span></div>
              </div>
            </div>
          </div>
          <div class="row row-center" *ngIf="!!challenge.challengeId">
            <div class="col-12">
              <div class="form-group">
                <label>{{'challenge_identifier' | translate}}</label>
                <input type="text" [ngModel]="challenge.challengeId"
                       name="challengeId"
                       class="form-control flat" [disabled]="true">
              </div>
            </div>
          </div>
          <div class="row row-center">
            <div class="col-6 col-lg-4">
              <div class="form-group">
                <label>{{'challenges_add_label_start_date' | translate}}</label>
                <input type="date" placeholder="yyyy-mm-dd" [ngModel]="challenge.startDate | date:'yyyy-MM-dd'"
                  name="startDate" class="form-control flat"
                  (ngModelChange)="challenge.startDate = readDate($event); checkForm();" [attr.expired]="isExpired()"
                  [disabled]="!isEditable">
              </div>
            </div>
            <div class="col-6 col-lg-4">
              <div class="form-group">
                <label>{{'challenges_add_label_end_date' | translate}}</label>
                <input type="date" placeholder="yyyy-mm-dd" [ngModel]="challenge.endDate  | date:'yyyy-MM-dd'"
                  name="endDate" class="form-control flat"
                  (ngModelChange)="challenge.endDate = readDate($event); checkForm();" [disabled]="!isEditable">
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <label>{{'challenges_add_label_theme' | translate}}</label>
              <div ngbDropdown class="d-block w-100">
                <button class="btn btn-standard" id="dropdownBasic2" ngbDropdownToggle [disabled]="!isEditable">
                  {{label}}
                  <i class="fa fa-angle-down" aria-hidden="true"></i>
                </button>
                <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic2">
                  <button *ngFor="let t of availableThemes" class="dropdown-item"
                    (click)="challenge.themeCode = t.code; label = t.label; checkForm();">{{t.label}}</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-center">
            <div class="col-12">
              <div class="form-group">
                <label>{{'app_description' | translate}}</label>
                <textarea [ngClass]="{ 'has-danger': shouldDisplayError(challenge.description) }"  [(ngModel)]="challenge.description" (ngModelChange)="checkForm()" name="description"
                  class="form-control description flat"  required [disabled]="!isEditable"></textarea>
                <span class="error-message" *ngIf="shouldDisplayError(challenge.description)">{{ 'challenge_manage_error_description_empty' | translate }}</span>
              </div>
            </div>
          </div>
          <div class="row row-center" *ngIf="true || !!challenge.creationDate || !!challenge.author">
            <div class="col-6" *ngIf="!!challenge.author">
              <div class="form-group">
                <label>{{'challenge_manage_creator' | translate}}</label>
                <input type="text" [ngModel]="challenge.author.firstname + ' ' + challenge.author.lastname"
                  name="creator" class="form-control flat" [disabled]="true">
              </div>
            </div>
            <div class="col-6" *ngIf="!!challenge.creationDate">
              <div class="form-group">
                <label>{{'group_manage_creation_date' | translate}}</label>
                <input type="date" placeholder="yyyy-MM-dd" [ngModel]="challenge.creationDate | date:'yyyy-MM-dd'"
                  name="creationDate" class="form-control flat" [disabled]="true">
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <div class="row row-center">
            <div class="col-12">
              <div class="form-group" *ngIf="challenge.conditions.nbTrip">
                <label>{{'challenges_add_label_conditions' | translate}}
                  <i class="icons8-info-2 info-icon" ngbTooltip="{{'challenges_add_info_conditions' | translate}}"></i>
                </label>
                <div class="row row-center">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-4" style="margin-top: 8px">
                    <span class="condition-label">{{'challenges_add_trip_number' | translate}}</span>
                  </div>
                  <div class="col-xl-9 col-md-9 col-lg-9 col-8" style="margin-top: 8px">
                    <div class="row row-center">
                      <div class="col-2 text-right">
                        <span class="option-slide">{{travelMin}}</span>
                      </div>
                      <div class="col-8">
                        <nouislider name="travel-slide" [min]="travelMin" [max]="travelMax" [step]="1"
                          [tooltips]="[ true ]" [(ngModel)]="challenge.conditions.nbTrip" (ngModelChange)="checkForm()" [disabled]="!isEditable">
                        </nouislider>
                      </div>
                      <div class="col-2 text-left">
                        <span class="option-slide">{{travelMax}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row row-center">
                  <div class="col-xl-3 col-lg-3 col-md-3 col-4" style="margin-top: 8px">
                    <span class="condition-label">{{'challenges_add_label_distance' | translate}}</span>
                  </div>
                  <div class="col-xl-9 col-md-9 col-lg-9 col-8" style="margin-top: 8px">
                    <div class="row row-center">
                      <div class="col-2 text-right">
                        <span class="option-slide">{{distanceMin}}km</span>
                      </div>
                      <div class="col-8">
                        <nouislider name="distance-slide" [min]="distanceMin" [max]="distanceMax" [step]="5"
                          [tooltips]="[ true ]" [(ngModel)]="distance" (ngModelChange)="checkForm()" [disabled]="!isEditable"></nouislider>
                      </div>
                      <div class="col-2 text-left">
                        <span class="option-slide">{{distanceMax}}km</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row row-center">
            <div class="col-12">
              <div class="form-group" *ngIf="isEditable">
                <label>{{'challenges_add_label_type'| translate}} </label>
                <!-- The multi-group options allow you to individually or team-class the drivers of the selected groups.-->
                <div class="row row-center">
                  <div class="col-12">
                    <input type="radio" value="0" name="visibility" [(ngModel)]="challenge.type" [value]="0"
                      (change)="onchallengeTypeChange()"> {{'challenges_add_type_all'| translate}} &nbsp;
                    <i class="icons8-info-2 info-icon" data-html="true"
                      ngbTooltip="{{'challenges_add_info_type_all' | translate}}"></i>
                  </div>
                  <div class="col-12">
                    <input type="radio" value="1" name="visibility" [(ngModel)]="challenge.type"
                      (ngModelChange)="checkForm()" [value]="1" (change)="onchallengeTypeChange()">
                    {{'challenges_add_type_single_group' | translate}}&nbsp;
                    <i class="icons8-info-2 info-icon" data-html="true"
                      ngbTooltip="{{'challenges_add_info_type_single_group' | translate}}"></i>
                  </div>
                  <div class="col-12">
                    <input type="radio" value="2" name="visibility" [(ngModel)]="challenge.type" [value]="3"
                      (change)="onchallengeTypeChange()"> {{'challenges_add_type_inter_group' | translate}}&nbsp;
                    <i class="icons8-info-2 info-icon" data-html="true"
                      ngbTooltip="{{'challenges_add_info_type_inter_group' | translate}}"></i>
                  </div>
                  <!--<div class="col-6"><input type="radio" value="3" name="visibility" [(ngModel)]="challenge.type" [value]="3"> Multi groupes, par équipes</div>-->
                </div>
              </div>
              <div class="form-group" *ngIf="!isEditable">
                <label>{{'challenges_add_label_type'| translate}}</label> :
                <span>{{typeDescriptions[challenge.type]}}</span>
              </div>
            </div>
          </div>

          <div *ngIf="isEditable && challenge.type > 0" class="row row-center">
            <div class="form-group operators">

              <label *ngIf="challenge.type == 1">{{'challenges_add_label_group_sing' | translate}}&nbsp;<i
                  class="icons8-info-2 info-icon" data-html="true"
                  ngbTooltip="{{'challenges_add_group_info' | translate}}"></i></label>
              <label *ngIf="challenge.type == 3">{{'challenges_add_label_group_plur' | translate}}&nbsp;<i
                  class="icons8-info-2 info-icon" data-html="true"
                  ngbTooltip="{{'challenges_add_group_info' | translate}}"></i></label>

              <div placement="left" ngbDropdown class="d-inline-block">
                <i class="fa fa-1x fa-plus-circle green" id="dropdownBasic1" ngbDropdownToggle></i>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-scroll">
                  <table class="table table-light">
                    <tr *ngFor="let group of getGroups()">
                      <td (click)="addToGroup(group)">{{group.label}}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="operator" *ngFor="let groupChallenge of groupsChallenge">
                <div class="row row-center">
                  <div class="col-11">
                    <span>{{groupChallenge.label}}</span>
                  </div>
                  <div class="col-1 trash text-center">
                    <i class="icons8-trash" (click)="removeChallenge(groupChallenge)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!isEditable && ((challenge.type > 0) && (challenge.groups !== undefined))" class="row row-center">
            <div class="form-group operators">
              <label *ngIf="challenge.type == 1">{{'challenges_add_readonly_group_sing' | translate}}</label>
              <label *ngIf="challenge.type == 3">{{'challenges_add_readonly_group_plur' | translate}}</label>
              <div class="operator" *ngFor="let groupChallenge of challenge.groups">
                <div class="row row-center">
                  <div class="col-12">
                    <span>{{groupChallenge.label}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row row-center" *ngIf="challenge.rules && isHyperAdmin()">
            <div class="col-12">
              <div class="form-group">
                <label>{{'challenges_add_label_rules'| translate}}</label>
                <a target="_blank" [routerLink]="['challenge-rules']" [queryParams]="{challengeId: challengeId}">
                  <i class="rules-link-icon"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
