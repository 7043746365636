import { Injectable } from '@angular/core';
import { LeaApiService } from './lea-api.service';

@Injectable()
export class DynamiclinkService {

  constructor(
		private _rest: LeaApiService) { }

    public getDriverList(){
      return this._rest.get('admin/drivers');
    }

    public reinvite(driver: string) {
      return this._rest.get('dynamiclink/reinvite/' +  driver);
    }

    public postNewInvitations(invits){
      return this._rest.post('dynamiclink/invite', invits)
    }
}
