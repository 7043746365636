import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class TitleAppService {

  // Observable string sources
  private titleApp = new Subject<string>();

  // Observable string streams
  titleApp$ = this.titleApp.asObservable();

  // Service message commands
  setTitle(title: string, titleToReplace) {
    if (title === 'All') { title = titleToReplace; } //'Communauté'
    this.titleApp.next(title);
  }

}
